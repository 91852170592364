export async function enableWakeOnLan() {
  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      console.log('Enabled wake up on LAN successful');
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const power = new Power();
    power.enableWakeOnLan(successCallbackObject, failureCallbackObject, { wakeOnLan: true });
  });
}

export async function enableImmediateRestart() {
  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      console.log('Enabled immediate restart successful');
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const power = new Power();
    power.setPowerOnDelay(successCallbackObject, failureCallbackObject, { delayTime: 0 });
  });
}
