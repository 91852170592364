<template>
  <div class="timetable" :style="{ backgroundColor: backgroundColor }">
    <h5 class="title" :style="getFontStyle(viewerStyles.title)">
      {{ getStopName() }}
    </h5>
    <div class="my-carousel">
      <PreLoader v-if="loading" class="styled-loader" />
      <ErrorState
        v-else-if="isError"
        title="Something went wrong"
        content="Sorry, we are unable to connect to Trafiklab at the moment."
      />
      <table v-else-if="hasData" class="my-table">
        <template>
          <tbody class="my-carousel-item" v-for="(data, i) in stopGroups[currentSlideIndex]">
            <tr :style="{ ...getRowStyle(i) }">
              <td>
                <span class="line-box" :style="getLineBoxStyle(viewerStyles?.line)">
                  {{ data.lineNumber }}
                </span>
              </td>
              <td>{{ getLocalizedDirectionText() }} {{ data.direction }}</td>
              <td class="datetime-info">
                {{ formatDateTime(data.departureTime, 'HH:mm') }}
              </td>
            </tr>
          </tbody>
        </template>
      </table>
      <EmptyState v-else content="Sorry, no data was returned from Trafiklab." />
    </div>
  </div>
</template>

<script>
  import localforage from 'localforage';
  import moment from 'moment';

  import { getRBGAColor, hex8ToRgba, isHex8 } from '@/helpers/utils';
  import { getDepartures } from '@/api/transport';
  import { TL_LANGUAGES, TL_TRAFFIC_TYPES } from '@/config/constants';
  import PreLoader from '@/components/common/PreLoader.vue';
  import { DEFAULT_TRAFIKLAB_VIEWER_STYLE } from '@/constants/trafiklabWidget';
  import ErrorState from '@/components/common/ui/errorState/ErrorState.vue';
  import EmptyState from '@/components/common/ui/emptyState/EmptyState.vue';
  import { cacheData } from '@/helpers/caching';
  import { SET_APP_DATA } from '@/store/actions/player';

  export default {
    name: 'TrafiklabViewer',

    emits: ['saveData'],

    components: { EmptyState, ErrorState, PreLoader },

    props: {
      app: {
        type: Object,
        default: () => null,
      },
      name: {
        type: String,
        default: '',
      },
      viewerStyles: {
        type: Object,
        default: () => ({}),
      },
      timetable: {
        type: Array,
        default: () => [],
      },
      carouselInterval: {
        type: Number,
        default: 15000,
      },
      pageSize: {
        type: Number,
        default: 6,
      },
    },

    data() {
      return {
        loading: false,
        isError: false,
        loadIntervalFunc: null,
        loadIntervalTime: 60 * 60 * 1000,
        slideTimeout: null,
        currentSlideIndex: 0,
        stopName: null,
      };
    },

    created() {
      if (!this.stopData) this.loadData();
      this.loadIntervalFunc = setInterval(this.loadData, this.loadIntervalTime);
      this.startSlideTimeout();
    },

    computed: {
      backgroundColor() {
        var opacity = 0;

        // handle migration from old hex6 values
        if (!isHex8(this.viewerStyles.background.color)) {
          opacity = 1 - this.viewerStyles.background.transparency / 100;
          return getRBGAColor(this.viewerStyles.background.color, opacity);
        } else {
          return hex8ToRgba(this.viewerStyles.background.color);
        }
      },

      hasData() {
        return this.stopData && this.stopData.length > 0;
      },

      filteredStopData() {
        const now = moment();
        const stops = this.stopData || [];

        return stops.filter((stop) => {
          const departureTime = moment(stop.departureTime);
          const differenceInMinutes = departureTime.diff(now, 'minutes');

          return differenceInMinutes >= this.timetable[0]?.hideTime || 0;
        });
      },

      stopGroups() {
        const groupCount = Math.ceil(this.filteredStopData.length / this.pageSize);
        const data = [];

        for (let i = 0; i < groupCount; i++) {
          const group = [];

          for (let j = 0; j < this.pageSize; j++) {
            const index = (i + 1) * j;

            if (!this.filteredStopData[index]) {
              break;
            }
            group.push(this.filteredStopData[index]);
          }
          data.push(group);
        }

        return data;
      },

      stopData() {
        return this.app && this.$store.state.player.appsData[this.app.item_id]
          ? this.$store.state.player.appsData[this.app.item_id].data
          : null;
      },
    },

    methods: {
      getNextSlideIndex() {
        return this.currentSlideIndex + 1 < this.stopGroups.length ? this.currentSlideIndex + 1 : 0;
      },

      startSlideTimeout() {
        this.slideTimeout = setTimeout(() => {
          this.currentSlideIndex = this.getNextSlideIndex();
          this.startSlideTimeout();
        }, this.carouselInterval);
      },

      stopSlideTimeout() {
        if (this.slideTimeout === null) return;

        clearTimeout(this.slideTimeout);
      },

      getStopName() {
        return this.stopName != null ? this.stopName : this.timetable[0].stop.name;
      },

      getLanguage() {
        return this.timetable[0].language;
      },

      getLocalizedDirectionText() {
        const language = this.getLanguage();

        if (language === TL_LANGUAGES.Swedish) {
          return 'mot';
        }

        return '';
      },

      getLocalizedNowText() {
        const language = this.getLanguage();

        if (language === TL_LANGUAGES.Swedish) {
          return 'Nu';
        } else {
          return 'Now';
        }
      },

      getFontStyle(style) {
        const fontSize = (style && `${style.fontSize}px`) || '12px';

        const formattedStyles = {
          fontStyle: 'normal',
          fontSize: fontSize,
          fontFamily: style?.fontType || 'Arial',
          color: hex8ToRgba(style?.fontColor || '#000000FF'),
        };

        if (['Italic', 'Bold Italic'].includes(style.fontStyle)) {
          formattedStyles['fontStyle'] = 'italic';
        }

        if (['Bold', 'Bold Italic'].includes(style.fontStyle)) {
          formattedStyles['fontWeight'] = 'bold';
        }

        return formattedStyles;
      },

      getLineBoxStyle(style) {
        return {
          color: hex8ToRgba(style?.fontColor || DEFAULT_TRAFIKLAB_VIEWER_STYLE.line.fontColor),
          backgroundColor: hex8ToRgba(
            style?.backgroundColor || DEFAULT_TRAFIKLAB_VIEWER_STYLE.line.backgroundColor,
          ),
        };
      },

      getRowStyle(index) {
        if ((index + 1) % 2 === 0) {
          return Object.assign({}, this.getFontStyle(this.viewerStyles.departures), {
            backgroundColor: hex8ToRgba(this.viewerStyles.evenDeparturesBackground),
          });
        }

        return Object.assign({}, this.getFontStyle(this.viewerStyles.departures), {
          backgroundColor: hex8ToRgba(this.viewerStyles.oddDeparturesBackground),
        });
      },

      async loadData() {
        this.isError = false;

        const stopData = [];
        this.loading = true;

        try {
          const timetable = this.timetable[0];
          const stopId = timetable && timetable.stop ? timetable.stop.id : null;

          if (!stopId) {
            return;
          }

          const lang = timetable.language;
          const transports = TL_TRAFFIC_TYPES.filter(
            (type) => timetable && timetable.trafficTypes[type] === true,
          );

          let departures = await getDepartures(stopId, transports, lang, timetable.hideTime);

          if (!departures) {
            departures = await localforage.getItem(`trafiklab-app-${this.app.item_id}`);

            if (!departures) {
              throw new Error(`Trafiklab cache fallback failed. Name: ${this.app.name}`);
            }
          }

          if (departures.length > 0) {
            this.stopName = departures[0].stopName;
          }

          departures.forEach((departure) => {
            if (!timetable.show && !moment(departure.scheduledTime).isAfter(moment())) {
              return;
            }

            stopData.push({
              lineNumber: departure.lineNumber,
              direction: departure.direction,
              departureTime: departure.scheduledTime,
            });
          });

          cacheData(`trafiklab-app-${this.app.item_id}:stop:${stopId}`, departures);

          this.$store.commit(SET_APP_DATA, {
            appId: this.app.item_id,
            appData: { appType: this.app.item_type, data: stopData },
          });
        } catch (error) {
          console.error('Failed to get data from Trafiklab');
          this.isError = true;
        }

        this.loading = false;
      },

      formatDateTime(dateTime, format = 'Y-MM-DD') {
        const now = moment();
        const departureTime = moment(dateTime);

        const diffInMinutes = departureTime.diff(now, 'minutes');

        if (
          diffInMinutes >= 0 &&
          diffInMinutes <= 20 &&
          now.date() === departureTime.date() &&
          now.hour() === departureTime.hour()
        ) {
          if (diffInMinutes === 0) {
            return this.getLocalizedNowText();
          }

          return `${diffInMinutes} min`;
        } else {
          return departureTime.format(format);
        }
      },
    },

    beforeDestroy() {
      clearInterval(this.loadIntervalFunc);
      this.stopSlideTimeout();
    },
  };
</script>

<style lang="scss" scoped>
  .styled-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 100%;
    height: 100%;
  }

  .my-carousel {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .my-carousel-item {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #dee2e6;

    tr {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      td:first-child {
        padding: 8px 12px;
        vertical-align: middle;
      }

      td:nth-child(2) {
        padding: 0;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      td:last-of-type {
        padding: 8px 12px;
        vertical-align: middle;
        margin-top: 0;
        margin-left: auto;
        min-width: 92px;
        white-space: nowrap;
      }
    }
  }

  .line-box {
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: 32px;
    padding: 8px;
    border-radius: 8px;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    box-sizing: content-box;
  }

  .datetime-info {
    vertical-align: middle;
    text-align: center;
  }

  .my-table {
    border-collapse: collapse;
    width: 100%;
  }

  .timetable {
    height: 100%;
    width: 100%;

    .title {
      padding: 16px;
      margin: 0;
    }
  }
</style>
