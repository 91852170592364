export async function enableKeepAliveMode() {
  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      console.log('Enabled keep alive mode successful');
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    var custom = new Custom();
    custom.Configuration.setKAM(successCallbackObject, failureCallbackObject, {
      keepAliveMode: true,
    });
  });
}

export async function tryGetWebOSVersion() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject: any) => {
      resolve({ webOSVersion: successObject.webOSVersion });
    };

    const failureCallbackObject = (cbObject: any) => {
      console.error('Failed to get WebOS version');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    var custom = new Custom();
    custom.Signage.getwebOSVersion(successCallbackObject, failureCallbackObject);
  });
}
