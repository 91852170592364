export const WEATHER_DISPLAY_TYPE = {
  NOW: {
    label: 'Now',
    value: 'now',
  },
  HOURS: {
    label: 'The next 7 hours',
    value: 'hours',
  },
  DAYS: {
    label: 'The next 5 days',
    value: 'days',
  },
};

export const WEATHER_TEMPERATURE = {
  CELSIUS: {
    label: 'Celsius (C°)',
    value: 'C',
  },
  FAHRENHEIT: {
    label: 'Fahrenheit (F°)',
    value: 'F',
  },
};

export const WEATHER_LANGUAGES = {
  ENGLISH: {
    label: 'English',
    value: 'en',
  },
  SWEDISH: {
    label: 'Swedish',
    value: 'sv',
  },
};

export const WEATHER_HOURS_FORMAT = {
  TWELVE: {
    label: '12 hours',
    value: 12,
    format: 'hh:mm A',
  },
  TWENTY_FOUR: {
    label: '24 hours',
    value: 24,
    format: 'HH:mm',
  },
};

export const WEATHER_DAY_FORMAT = {
  SHORT: {
    label: 'Mon - Sun',
    value: 'short',
    format: 'ddd',
  },
  WORD: {
    label: 'Monday - Sunday',
    value: 'word',
    format: 'dddd',
  },
  DAY_OF_MONTH: {
    label: '1st - 30th',
    value: 'letter',
    format: 'Do',
  },
};

export const weatherDefaultOptions = {
  name: '',
  location: null,
  backgroundColor: '#FFFFFF',
  fontColor: '#151515',
  iconColor: '#151515',
  type: WEATHER_DISPLAY_TYPE.NOW.value,
  textFont: 'Poppins',
  dayFormat: WEATHER_DAY_FORMAT.SHORT.value,
  language: WEATHER_LANGUAGES.ENGLISH.value,
  timeFormat: WEATHER_HOURS_FORMAT.TWENTY_FOUR.value,
  temperatureUnit: WEATHER_TEMPERATURE.CELSIUS.value,
  folder: null,
};

export const WEATHER_APP_SCALES = {
  noLocation: {
    now: {
      container: 100 / 376,
      icon: {
        width: 100 / 376,
        height: 100 / 100,
      },
      numbers: {
        fontSize: 42 / 100,
        lineHeight: 44 / 100,
      },
      padding: {
        bottom: 0,
        left: 31 / 376,
        right: 22 / 376,
        top: 0,
      },
      title: {
        fontSize: 0,
        lineHeight: 0,
      },
    },
    multiple: {
      container: 110 / 376,
      bodyHeight: {
        days: 79 / 110,
        hours: 83 / 110,
      },
      icon: 25 / 376,
      itemWidth: 40 / 376,
      numbers: {
        fontSize: 12 / 110,
        lineHeight: 18 / 110,
      },
      padding: {
        bottom: 16 / 110,
        left: 19 / 376,
        right: 19 / 376,
        top: 16 / 110,
      },
      title: {
        fontSize: 0,
        lineHeight: 0,
      },
      lowerTemp: {
        fontSize: 8 / 120,
        lineHeight: 12 / 120,
      },
    },
  },
  location: {
    now: {
      container: 140 / 376,
      icon: {
        width: 100 / 376,
        height: 100 / 140,
      },
      numbers: {
        fontSize: 42 / 140,
        lineHeight: 44 / 140,
      },
      padding: {
        bottom: 22 / 140,
        left: 31 / 376,
        right: 22 / 376,
        top: 22 / 140,
      },
      title: {
        fontSize: 10 / 140,
        lineHeight: 15 / 140,
      },
    },
    multiple: {
      container: 158 / 376,
      bodyHeight: {
        days: 79 / 158,
        hours: 83 / 158,
      },
      icon: 25 / 376,
      itemWidth: 40 / 376,
      numbers: {
        fontSize: 12 / 158,
        lineHeight: 18 / 158,
      },
      padding: {
        bottom: 16 / 158,
        left: 19 / 376,
        right: 19 / 376,
        top: 16 / 158,
      },
      title: {
        fontSize: 10 / 158,
        lineHeight: 15 / 158,
      },
      lowerTemp: {
        fontSize: 8 / 158,
        lineHeight: 12 / 158,
      },
    },
  },
};

export const weatherIcons = {
  1: {
    id: 1,
    width: 76,
    height: 76,
    paths: [
      {
        d: 'M62.9506 62.8611L52.0325 52.7974C51.355 52.1736 51.321 51.1175 51.9597 50.4525C52.5985 49.7891 53.677 49.7574 54.3562 50.3828L65.2743 60.4449C65.9518 61.0703 65.9842 62.1248 65.3454 62.7898C64.7083 63.4548 63.6298 63.4865 62.9506 62.8611Z',
      },
      {
        d: 'M21.2381 24.6656L10.3199 14.6035C9.6408 13.9781 9.60846 12.9236 10.2472 12.2586C10.8859 11.5936 11.9628 11.5619 12.642 12.1873L23.5601 22.251C24.2393 22.8764 24.2716 23.9309 23.6329 24.5959C22.9942 25.2593 21.9156 25.291 21.2381 24.6656Z',
      },
      {
        d: 'M12.511 62.7361L22.5317 51.8143C23.1542 51.1366 24.2312 51.0796 24.9249 51.6892C25.6186 52.2988 25.6752 53.3533 25.0526 54.0325L15.0319 64.9544C14.4094 65.632 13.3325 65.689 12.6404 65.0794C11.9467 64.4699 11.8885 63.4154 12.511 62.7361Z',
      },
      {
        d: 'M50.54 21.0154L60.5607 10.0936C61.1832 9.4159 62.2601 9.3589 62.9539 9.96849C63.6459 10.5781 63.7041 11.6326 63.0816 12.3118L53.0609 23.2337C52.4384 23.9113 51.3614 23.9683 50.6677 23.3587C49.9756 22.7492 49.9174 21.6947 50.54 21.0154Z',
      },
      {
        d: 'M36.8277 72.9571L36.6563 58.2765C36.6466 57.3645 37.4002 56.6092 38.3316 56.5997C39.263 56.5886 40.0343 57.3265 40.0456 58.2385L40.217 72.9191C40.2267 73.8327 39.4732 74.5864 38.5418 74.5975C37.6104 74.6086 36.8391 73.8707 36.8277 72.9571Z',
      },
      {
        d: 'M35.9901 17.0427L35.8187 2.36205C35.8074 1.44847 36.5609 0.6948 37.4923 0.683717C38.4238 0.672634 39.1951 1.41047 39.2064 2.32405L39.3778 17.0047C39.3891 17.9167 38.634 18.672 37.7026 18.6815C36.7712 18.6926 35.9998 17.9547 35.9901 17.0427Z',
      },
      {
        d: 'M1.96952 35.959L16.9642 36.0065C17.8956 36.0097 18.6556 36.7586 18.6523 37.6706C18.6507 38.5826 17.8859 39.3267 16.9528 39.3236L1.95982 39.2777C1.0268 39.2745 0.266796 38.5256 0.27003 37.6136C0.273264 36.7 1.03811 35.9558 1.96952 35.959Z',
      },
      {
        d: 'M59.0811 35.957L74.0757 36.003C75.0071 36.0061 75.7671 36.755 75.7639 37.667C75.7607 38.5806 74.9958 39.3248 74.0644 39.3216L59.0698 39.2741C58.1384 39.271 57.3784 38.522 57.3816 37.61C57.3848 36.698 58.1497 35.9539 59.0811 35.957Z',
      },
      {
        d: 'M37.5974 22.2588C28.9366 22.2588 21.8896 29.159 21.8896 37.6393C21.8896 46.1212 28.9366 53.0214 37.5974 53.0214C46.2598 53.0214 53.3068 46.1212 53.3068 37.6393C53.3068 29.159 46.2598 22.2588 37.5974 22.2588ZM50.097 37.6393C50.097 44.3875 44.4891 49.8785 37.5974 49.8785C30.7056 49.8785 25.0994 44.3875 25.0994 37.6393C25.0994 30.8911 30.7056 25.4001 37.5974 25.4001C44.4891 25.4001 50.097 30.8911 50.097 37.6393Z',
      },
    ],
  },
  2: {
    id: 2,
    width: 95,
    height: 70,
    paths: [
      {
        d: 'M79.7017 39.7434C78.6796 39.7434 77.6592 39.8525 76.6617 40.069C73.471 32.6588 66.0282 27.7148 57.9745 27.7148C47.9242 27.7148 39.5674 34.8174 37.9033 44.6809C37.8804 44.6809 37.8574 44.6809 37.8329 44.6809C31.2615 44.6809 25.9153 50.0448 25.9153 56.6395C25.9153 63.2309 31.2615 68.5932 37.8329 68.5932H79.7017C87.6276 68.5932 94.0761 62.1223 94.0761 54.1683C94.0761 46.2144 87.6276 39.7434 79.7017 39.7434ZM91.1852 54.1683C91.1852 60.5286 86.0339 65.7037 79.7017 65.7037H37.8329C32.8552 65.7037 28.8062 61.6372 28.8062 56.6395C28.8062 51.6386 32.8552 47.5704 37.8329 47.5704C38.1654 47.5704 38.529 47.5965 38.9761 47.6518L40.4454 47.8341L40.5928 46.3625C41.4986 37.3797 48.9724 30.606 57.9745 30.606C65.3386 30.606 71.9493 35.296 74.4243 42.2797L74.8911 43.5983L76.2227 43.1734C77.3545 42.8153 78.524 42.6346 79.7017 42.6346C86.0339 42.6346 91.1852 47.8081 91.1852 54.1683Z',
      },
      {
        d: 'M20.7559 21.2821C21.3275 21.9202 22.3201 21.9788 22.9621 21.4123C23.6059 20.8442 23.6648 19.8577 23.0932 19.2195L13.9093 8.9393C13.3393 8.30116 12.3467 8.24256 11.703 8.8107C11.0609 9.37721 11.002 10.3637 11.572 11.0035L20.7559 21.2821Z',
      },
      {
        d: 'M19.6584 46.7045L9.53269 56.0682C8.90373 56.651 8.86769 57.6375 9.45243 58.2643C10.0388 58.8894 11.033 58.9252 11.662 58.3424L21.7877 48.9787C22.4167 48.3975 22.4527 47.4094 21.8679 46.7843C21.2816 46.1592 20.2873 46.1234 19.6584 46.7045Z',
      },
      {
        d: 'M60.3201 10.9952C59.7337 10.3701 58.7395 10.3343 58.1105 10.9155L47.9848 20.2792C47.3559 20.862 47.3198 21.8485 47.9062 22.4752C48.491 23.1003 49.4852 23.1362 50.1142 22.5534L60.2398 13.1896C60.8688 12.6085 60.9048 11.6203 60.3201 10.9952Z',
      },
      {
        d: 'M36.388 17.0505C37.2447 17.1173 37.9998 16.4743 38.0669 15.6229L39.1463 1.91913C39.2135 1.06774 38.5665 0.315646 37.7099 0.248901C36.8532 0.182157 36.0965 0.82518 36.0293 1.67658L34.9516 15.3803C34.8844 16.2333 35.5298 16.9838 36.388 17.0505Z',
      },
      {
        d: 'M17.2866 33.1959C17.3652 32.3461 16.7297 31.5859 15.8747 31.5061L2.10301 30.2331C1.24637 30.155 0.481456 30.785 0.402836 31.6364C0.322577 32.4878 0.958094 33.248 1.81309 33.3261L15.5848 34.5992C16.4415 34.6789 17.2064 34.0473 17.2866 33.1959Z',
      },
      {
        d: 'M23.2112 33.6962C23.7795 27.4027 29.3927 22.742 35.725 23.3069C38.9238 23.5934 41.7001 25.1594 43.5936 27.4499C44.2832 26.7759 45.035 26.1345 45.8507 25.5452C43.4707 22.6866 39.9918 20.7331 35.9887 20.3766C28.0316 19.6669 20.977 25.5241 20.2629 33.4324C19.801 38.5441 22.1072 43.2683 25.9383 46.1708C26.3707 45.4317 26.9538 44.6243 27.7286 43.8331C24.6804 41.5231 22.8443 37.7627 23.2112 33.6962Z',
      },
    ],
  },
  3: {
    id: 3,
    width: 99,
    height: 73,
    paths: [
      {
        d: 'M83.2153 41.4474C82.1608 41.4474 81.1079 41.5612 80.0787 41.787C76.7866 34.0592 69.1073 28.9033 60.7975 28.9033C50.4277 28.9033 41.8054 36.3103 40.0883 46.5965C40.0647 46.5965 40.041 46.5965 40.0157 46.5965C33.2354 46.5965 27.7192 52.1903 27.7192 59.0676C27.7192 65.9415 33.2354 71.5336 40.0157 71.5336H83.2153C91.3932 71.5336 98.0468 64.7854 98.0468 56.4905C98.0468 48.1957 91.3932 41.4474 83.2153 41.4474ZM95.0639 56.4905C95.0639 63.1233 89.7489 68.5202 83.2153 68.5202H40.0157C34.8798 68.5202 30.7021 64.2795 30.7021 59.0676C30.7021 53.8523 34.8798 49.6099 40.0157 49.6099C40.3587 49.6099 40.7339 49.637 41.1953 49.6947L42.7112 49.8849L42.8633 48.3502C43.7979 38.9824 51.5093 31.9184 60.7975 31.9184C68.3958 31.9184 75.2166 36.8094 77.7702 44.0924L78.2518 45.4675L79.6258 45.0244C80.7936 44.6509 82.0002 44.4625 83.2153 44.4625C89.7489 44.4625 95.0639 49.8577 95.0639 56.4905Z',
      },
      {
        d: 'M22.3957 22.1937C22.9855 22.8592 24.0096 22.9203 24.6721 22.3295C25.3363 21.737 25.3971 20.7082 24.8073 20.0427L15.3315 9.32191C14.7434 8.65642 13.7192 8.59531 13.0551 9.18779C12.3926 9.77858 12.3318 10.8074 12.9199 11.4746L22.3957 22.1937Z',
      },
      {
        d: 'M21.2634 48.7066L10.8158 58.4716C10.1668 59.0794 10.1297 60.1082 10.733 60.7618C11.338 61.4137 12.3638 61.4511 13.0128 60.8433L23.4604 51.0783C24.1093 50.4722 24.1465 49.4417 23.5432 48.7898C22.9381 48.1379 21.9123 48.1006 21.2634 48.7066Z',
      },
      {
        d: 'M63.2177 11.4666C62.6127 10.8147 61.5869 10.7773 60.9379 11.3834L50.4904 21.1484C49.8414 21.7562 49.8042 22.785 50.4092 23.4386C51.0126 24.0905 52.0384 24.1278 52.6874 23.5201L63.1349 13.755C63.7839 13.149 63.8211 12.1185 63.2177 11.4666Z',
      },
      {
        d: 'M38.5252 17.7815C39.409 17.8512 40.1881 17.1806 40.2574 16.2927L41.3711 2.00166C41.4404 1.11378 40.7729 0.329452 39.889 0.259848C39.0051 0.190243 38.2243 0.860824 38.1551 1.74871L37.043 16.0397C36.9737 16.9293 37.6396 17.7119 38.5252 17.7815Z',
      },
      {
        d: 'M18.8162 34.6191C18.8973 33.7329 18.2416 32.9401 17.3594 32.8569L3.14995 31.5293C2.26608 31.4478 1.47685 32.1048 1.39573 32.9927C1.31292 33.8806 1.96864 34.6734 2.85082 34.7549L17.0603 36.0825C17.9442 36.1656 18.7334 35.5069 18.8162 34.6191Z',
      },
      {
        d: 'M24.9291 35.1406C25.5155 28.5774 31.3071 23.7169 37.8406 24.306C41.1412 24.6048 44.0057 26.238 45.9594 28.6266C46.6709 27.9238 47.4466 27.2549 48.2882 26.6403C45.8326 23.6592 42.2431 21.622 38.1127 21.2502C29.9027 20.51 22.6239 26.6183 21.8871 34.8656C21.4105 40.1963 23.79 45.1229 27.7429 48.1499C28.1891 47.3791 28.7907 46.5371 29.5901 45.712C26.445 43.303 24.5505 39.3814 24.9291 35.1406Z',
      },
    ],
  },
  4: {
    id: 4,
    width: 99,
    height: 73,
    paths: [
      {
        d: 'M83.2153 41.4474C82.1608 41.4474 81.1079 41.5612 80.0787 41.787C76.7866 34.0592 69.1073 28.9033 60.7975 28.9033C50.4277 28.9033 41.8054 36.3103 40.0883 46.5965C40.0647 46.5965 40.041 46.5965 40.0157 46.5965C33.2354 46.5965 27.7192 52.1903 27.7192 59.0676C27.7192 65.9415 33.2354 71.5336 40.0157 71.5336H83.2153C91.3932 71.5336 98.0468 64.7854 98.0468 56.4905C98.0468 48.1957 91.3932 41.4474 83.2153 41.4474ZM95.0639 56.4905C95.0639 63.1233 89.7489 68.5203 83.2153 68.5203H40.0157C34.8798 68.5203 30.7021 64.2795 30.7021 59.0676C30.7021 53.8523 34.8798 49.6099 40.0157 49.6099C40.3587 49.6099 40.7339 49.637 41.1953 49.6947L42.7112 49.8849L42.8633 48.3502C43.7979 38.9824 51.5093 31.9184 60.7975 31.9184C68.3958 31.9184 75.2166 36.8094 77.7702 44.0924L78.2518 45.4675L79.6258 45.0244C80.7936 44.6509 82.0002 44.4625 83.2153 44.4625C89.7489 44.4625 95.0639 49.8577 95.0639 56.4905Z',
      },
      {
        d: 'M22.3957 22.1937C22.9855 22.8592 24.0096 22.9203 24.6721 22.3295C25.3363 21.737 25.3971 20.7082 24.8073 20.0427L15.3315 9.32191C14.7434 8.65642 13.7192 8.59531 13.0551 9.18779C12.3926 9.77858 12.3318 10.8074 12.9199 11.4746L22.3957 22.1937',
      },
      {
        d: 'M21.2634 48.7066L10.8158 58.4716C10.1668 59.0794 10.1297 60.1082 10.733 60.7618C11.338 61.4137 12.3638 61.4511 13.0128 60.8433L23.4604 51.0783C24.1093 50.4722 24.1465 49.4417 23.5432 48.7898C22.9381 48.1379 21.9123 48.1006 21.2634 48.7066',
      },
      {
        d: 'M63.2177 11.4666C62.6127 10.8147 61.5869 10.7773 60.9379 11.3834L50.4904 21.1484C49.8414 21.7562 49.8042 22.785 50.4092 23.4386C51.0126 24.0905 52.0384 24.1278 52.6874 23.5201L63.1349 13.755C63.7839 13.149 63.8211 12.1185 63.2177 11.4666',
      },
      {
        d: 'M38.5252 17.7815C39.409 17.8512 40.1881 17.1806 40.2574 16.2927L41.3711 2.00166C41.4404 1.11378 40.7729 0.329452 39.889 0.259848C39.0051 0.190243 38.2243 0.860824 38.1551 1.74871L37.043 16.0397C36.9737 16.9293 37.6396 17.7119 38.5252 17.7815',
      },
      {
        d: 'M18.8162 34.6191V34.6191C18.8973 33.7329 18.2416 32.9401 17.3594 32.8569L3.14995 31.5293C2.26608 31.4478 1.47685 32.1048 1.39573 32.9927C1.31292 33.8806 1.96864 34.6734 2.85082 34.7549L17.0603 36.0825C17.9442 36.1656 18.7334 35.5069 18.8162 34.6191',
      },
      {
        d: 'M24.9291 35.1406C25.5155 28.5774 31.3071 23.7169 37.8406 24.306C41.1412 24.6048 44.0057 26.238 45.9594 28.6266C46.6709 27.9238 47.4466 27.2549 48.2882 26.6403C45.8326 23.6592 42.2431 21.622 38.1127 21.2502C29.9027 20.51 22.6239 26.6183 21.8871 34.8656C21.4105 40.1963 23.79 45.1229 27.7429 48.1499C28.1891 47.3791 28.7907 46.5371 29.5901 45.712C26.445 43.303 24.5505 39.3814 24.9291 35.1406',
      },
    ],
  },
  5: {
    id: 5,
    width: 79,
    height: 80,
    paths: [
      {
        d: 'M4.59197 56.807H76.3505C77.3829 56.807 78.2276 56.0145 78.2276 55.0443C78.2276 54.0757 77.3829 53.2832 76.3505 53.2832H4.59197C3.55955 53.2832 2.71484 54.0757 2.71484 55.0443C2.71484 56.0145 3.55955 56.807 4.59197 56.807Z',
      },
      {
        d: 'M21.3054 68.1918H76.3505C77.3829 68.1918 78.2276 67.3993 78.2276 66.4291C78.2276 65.4605 77.3829 64.668 76.3505 64.668H21.3054C20.273 64.668 19.4265 65.4605 19.4265 66.4291C19.4265 67.3993 20.273 68.1918 21.3054 68.1918Z',
      },
      {
        d: 'M61.7489 13.68C60.5757 13.68 59.406 13.8017 58.2623 14.04C54.6054 5.84333 46.0714 0.375 36.8387 0.375C25.317 0.375 15.7349 8.23167 13.8283 19.1417C13.8004 19.1417 13.7744 19.1417 13.7466 19.1417C6.21371 19.1417 0.0834961 25.075 0.0834961 32.3683C0.0834961 39.6617 6.21371 45.5933 13.7466 45.5933H61.7489C70.8356 45.5933 78.2277 38.435 78.2277 29.6367C78.2277 20.8383 70.8356 13.68 61.7489 13.68ZM74.9132 29.6367C74.9132 36.6733 69.0089 42.3967 61.7489 42.3967H13.7466C8.04044 42.3967 3.39802 37.8983 3.39802 32.3683C3.39802 26.8383 8.04044 22.3383 13.7466 22.3383C14.1272 22.3383 14.5443 22.3667 15.0571 22.4283L16.7413 22.63L16.9099 21.0017C17.9492 11.065 26.518 3.57167 36.8387 3.57167C45.2823 3.57167 52.8604 8.76167 55.6969 16.4867L56.234 17.945L57.76 17.475C59.0566 17.0783 60.3984 16.8767 61.7489 16.8767C69.0089 16.8767 74.9132 22.6 74.9132 29.6367Z',
      },
      {
        d: 'M39.4528 79.1205H76.3506C77.383 79.1205 78.2277 78.3276 78.2277 77.3586C78.2277 76.3895 77.383 75.5967 76.3506 75.5967H39.4528C38.4187 75.5967 37.574 76.3895 37.574 77.3586C37.574 78.3276 38.4187 79.1205 39.4528 79.1205Z',
      },
    ],
  },
  6: {
    id: 6,
    width: 100,
    height: 70,
    paths: [
      {
        d: 'M84.0972 37.7396C83.0015 37.7396 81.9075 37.8596 80.8382 38.0946C77.419 30.023 69.4421 24.6396 60.8101 24.6396C50.0378 24.6396 41.0807 32.3746 39.2986 43.118C39.2722 43.118 39.2474 43.118 39.2227 43.118C32.1798 43.118 26.4487 48.9613 26.4487 56.1413C26.4487 63.3213 32.1798 69.163 39.2227 69.163H84.0972C92.5923 69.163 99.5032 62.1146 99.5032 53.4513C99.5032 44.788 92.5923 37.7396 84.0972 37.7396ZM96.4042 53.4513C96.4042 60.3797 90.8827 66.0146 84.0972 66.0146H39.2227C33.8877 66.0146 29.5477 61.5863 29.5477 56.1413C29.5477 50.6963 33.8877 46.2646 39.2227 46.2646C39.5791 46.2646 39.9669 46.293 40.4471 46.353L42.0213 46.553L42.1798 44.9496C43.1517 35.1646 51.1616 27.7863 60.8101 27.7863C68.7029 27.7863 75.7887 32.8963 78.4388 40.503L78.9405 41.938L80.3679 41.4763C81.5807 41.0863 82.8349 40.888 84.0972 40.888C90.8827 40.888 96.4042 46.523 96.4042 53.4513Z',
      },
      {
        d: 'M21.2771 22.2678C21.8447 22.8945 22.8167 22.9378 23.4371 22.3645C24.0576 21.7895 24.1005 20.8078 23.5329 20.1812L14.3942 10.1012C13.8266 9.47451 12.853 9.43118 12.2342 10.0062C11.6137 10.5795 11.5708 11.5612 12.1385 12.1878L21.2771 22.2678Z',
      },
      {
        d: 'M20.5856 47.5597L10.82 57.0213C10.2127 57.6097 10.1913 58.5913 10.7738 59.2047C11.3563 59.818 12.3299 59.838 12.9371 59.2497L22.7028 49.7897C23.3084 49.2013 23.3299 48.218 22.7474 47.6047C22.1649 46.993 21.1913 46.9713 20.5856 47.5597Z',
      },
      {
        d: 'M36.5048 17.827C37.3448 17.8803 38.0741 17.2303 38.1269 16.3836L38.9768 2.74198C39.0296 1.89365 38.386 1.15698 37.5477 1.10365C36.7078 1.05031 35.9784 1.70031 35.9256 2.54865L35.0758 16.1886C35.023 17.037 35.6665 17.7736 36.5048 17.827Z',
      },
      {
        d: 'M18.0609 34.1667C18.1269 33.3201 17.4932 32.5734 16.655 32.5067L3.16321 31.4501C2.32493 31.3834 1.58565 32.0234 1.51965 32.8701C1.45529 33.7168 2.0873 34.4634 2.92724 34.5301L16.419 35.5868C17.2573 35.6534 17.9965 35.0134 18.0609 34.1667Z',
      },
      {
        d: 'M41.9307 23.1263C40.2046 22.163 38.2591 21.2963 36.1666 21.1396C28.3713 20.553 21.5561 26.483 20.9769 34.3563C20.6023 39.443 22.929 44.1063 26.7195 46.9346C27.1336 46.193 27.6914 45.3813 28.4356 44.583C25.4191 42.3313 23.566 38.6213 23.8647 34.573C24.3267 28.308 29.7492 23.5896 35.9505 24.0563C37.1666 24.148 36.8366 23.833 37.9059 24.278L38.3201 24.4363C38.3201 24.4363 39.6204 23.088 41.9307 23.1263Z',
      },
      {
        d: 'M91.6764 33.1752C91.6764 26.2385 86.1434 20.5952 79.3415 20.5952C78.4636 20.5952 77.5873 20.6902 76.7309 20.8785C73.9949 14.4152 67.6071 10.1035 60.6946 10.1035C52.0708 10.1035 44.8976 16.2985 43.4702 24.9002C43.4504 24.9002 43.4289 24.9002 43.4091 24.9002C37.7705 24.9002 33.1814 29.5785 33.1814 35.3285C33.1814 37.0452 33.6022 38.6568 34.3266 40.0835C34.9058 39.7935 35.7177 39.5035 36.7639 39.3368C36.0758 38.1602 35.6632 36.7969 35.6632 35.3285C35.6632 30.9685 39.1385 27.4202 43.4091 27.4202C43.6946 27.4202 44.0065 27.4435 44.3893 27.4918L45.65 27.6502L45.7771 26.3668C46.556 18.5335 52.9685 12.6252 60.6946 12.6252C67.0147 12.6252 72.688 16.7168 74.8118 22.8068L75.2127 23.9568L76.3563 23.5868C77.3266 23.2735 78.3299 23.1152 79.3415 23.1152C84.7755 23.1152 89.1962 27.6285 89.1962 33.1752C89.1962 33.7402 89.1418 34.2918 89.0527 34.8318C89.7342 34.9935 90.5873 35.2752 91.4042 35.7502C91.5758 34.9185 91.6764 34.0585 91.6764 33.1752Z',
      },
    ],
  },
  7: {
    id: 7,
    width: 96,
    height: 47,
    paths: [
      {
        d: 'M17.051 33.2294H10.4196C6.09666 33.2294 2.58011 29.6956 2.58011 25.351C2.58011 21.005 6.09666 17.4697 10.4196 17.4697C10.7076 17.4697 11.0237 17.4932 11.4121 17.5402L12.6882 17.6988L12.8152 16.421C13.6039 8.61316 20.0935 2.72641 27.9138 2.72641C31.3092 2.72641 34.5141 3.88525 37.1017 5.871C37.66 5.23944 38.2448 4.64166 38.8622 4.085C35.7902 1.64688 31.9428 0.214844 27.9138 0.214844C19.1837 0.214844 11.9261 6.38653 10.4816 14.9596C10.461 14.9596 10.4403 14.9596 10.4196 14.9596C4.71278 14.9596 0.0693359 19.6214 0.0693359 25.351C0.0693359 31.0792 4.71278 35.741 10.4196 35.741H17.1101C17.051 35.221 17.0096 34.6938 17.0096 34.1562C17.0096 33.8433 17.0303 33.5364 17.051 33.2294Z',
      },
      {
        d: 'M78.9313 14.9841C77.8176 14.9841 76.707 15.103 75.6215 15.3395C72.1492 7.26725 64.0468 1.88281 55.2798 1.88281C44.3402 1.88281 35.2424 9.61872 33.4317 20.3626C33.4051 20.3626 33.38 20.3626 33.3549 20.3626C26.2007 20.3626 20.3816 26.2053 20.3816 33.386C20.3816 40.5667 26.2007 46.408 33.3549 46.408H78.9313C87.5594 46.408 94.5793 39.3594 94.5793 30.6968C94.5793 22.0326 87.5594 14.9841 78.9313 14.9841ZM91.4319 30.6968C91.4319 37.6248 85.8241 43.2604 78.9313 43.2604H33.3549C27.9361 43.2604 23.5274 38.8322 23.5274 33.386C23.5274 27.9399 27.9361 23.5102 33.3549 23.5102C33.7153 23.5102 34.1111 23.5381 34.5985 23.5983L36.1965 23.7966L36.3575 22.1942C37.3455 12.4093 45.4804 5.03034 55.2798 5.03034C63.2965 5.03034 70.4921 10.1416 73.186 17.7468L73.6941 19.1832L75.1444 18.7206C76.3762 18.3299 77.6493 18.1331 78.9313 18.1331C85.8241 18.1331 91.4319 23.7687 91.4319 30.6968Z',
      },
    ],
  },
  8: {
    id: 8,
    width: 96,
    height: 47,
    paths: [
      {
        d: 'M17.051 33.2294H10.4196C6.09666 33.2294 2.58011 29.6956 2.58011 25.351C2.58011 21.005 6.09666 17.4697 10.4196 17.4697C10.7076 17.4697 11.0237 17.4932 11.4121 17.5402L12.6882 17.6988L12.8152 16.421C13.6039 8.61316 20.0935 2.72641 27.9138 2.72641C31.3092 2.72641 34.5141 3.88525 37.1017 5.871C37.66 5.23944 38.2448 4.64166 38.8622 4.085C35.7902 1.64688 31.9428 0.214844 27.9138 0.214844C19.1837 0.214844 11.9261 6.38653 10.4816 14.9596C10.461 14.9596 10.4403 14.9596 10.4196 14.9596C4.71278 14.9596 0.0693359 19.6214 0.0693359 25.351C0.0693359 31.0792 4.71278 35.741 10.4196 35.741H17.1101C17.051 35.221 17.0096 34.6938 17.0096 34.1562C17.0096 33.8433 17.0303 33.5364 17.051 33.2294Z',
      },
      {
        d: 'M78.9313 14.9841C77.8176 14.9841 76.707 15.103 75.6215 15.3395C72.1492 7.26725 64.0468 1.88281 55.2798 1.88281C44.3402 1.88281 35.2424 9.61872 33.4317 20.3626C33.4051 20.3626 33.38 20.3626 33.3549 20.3626C26.2007 20.3626 20.3816 26.2053 20.3816 33.386C20.3816 40.5667 26.2007 46.408 33.3549 46.408H78.9313C87.5594 46.408 94.5793 39.3594 94.5793 30.6968C94.5793 22.0326 87.5594 14.9841 78.9313 14.9841ZM91.4319 30.6968C91.4319 37.6248 85.8241 43.2604 78.9313 43.2604H33.3549C27.9361 43.2604 23.5274 38.8322 23.5274 33.386C23.5274 27.9399 27.9361 23.5102 33.3549 23.5102C33.7153 23.5102 34.1111 23.5381 34.5985 23.5983L36.1965 23.7966L36.3575 22.1942C37.3455 12.4093 45.4804 5.03034 55.2798 5.03034C63.2965 5.03034 70.4921 10.1416 73.186 17.7468L73.6941 19.1832L75.1444 18.7206C76.3762 18.3299 77.6493 18.1331 78.9313 18.1331C85.8241 18.1331 91.4319 23.7687 91.4319 30.6968Z',
      },
    ],
  },
  11: {
    id: 11,
    width: 79,
    height: 80,
    paths: [
      {
        d: 'M4.59197 56.807H76.3505C77.3829 56.807 78.2276 56.0145 78.2276 55.0443C78.2276 54.0757 77.3829 53.2832 76.3505 53.2832H4.59197C3.55955 53.2832 2.71484 54.0757 2.71484 55.0443C2.71484 56.0145 3.55955 56.807 4.59197 56.807Z',
      },
      {
        d: 'M21.3054 68.1918H76.3505C77.3829 68.1918 78.2276 67.3993 78.2276 66.4291C78.2276 65.4605 77.3829 64.668 76.3505 64.668H21.3054C20.273 64.668 19.4265 65.4605 19.4265 66.4291C19.4265 67.3993 20.273 68.1918 21.3054 68.1918Z',
      },
      {
        d: 'M61.7489 13.68C60.5757 13.68 59.406 13.8017 58.2623 14.04C54.6054 5.84333 46.0714 0.375 36.8387 0.375C25.317 0.375 15.7349 8.23167 13.8283 19.1417C13.8004 19.1417 13.7744 19.1417 13.7466 19.1417C6.21371 19.1417 0.0834961 25.075 0.0834961 32.3683C0.0834961 39.6617 6.21371 45.5933 13.7466 45.5933H61.7489C70.8356 45.5933 78.2277 38.435 78.2277 29.6367C78.2277 20.8383 70.8356 13.68 61.7489 13.68ZM74.9132 29.6367C74.9132 36.6733 69.0089 42.3967 61.7489 42.3967H13.7466C8.04044 42.3967 3.39802 37.8983 3.39802 32.3683C3.39802 26.8383 8.04044 22.3383 13.7466 22.3383C14.1272 22.3383 14.5443 22.3667 15.0571 22.4283L16.7413 22.63L16.9099 21.0017C17.9492 11.065 26.518 3.57167 36.8387 3.57167C45.2823 3.57167 52.8604 8.76167 55.6969 16.4867L56.234 17.945L57.76 17.475C59.0566 17.0783 60.3984 16.8767 61.7489 16.8767C69.0089 16.8767 74.9132 22.6 74.9132 29.6367Z',
      },
      {
        d: 'M39.4528 79.1205H76.3506C77.383 79.1205 78.2277 78.3276 78.2277 77.3586C78.2277 76.3895 77.383 75.5967 76.3506 75.5967H39.4528C38.4187 75.5967 37.574 76.3895 37.574 77.3586C37.574 78.3276 38.4187 79.1205 39.4528 79.1205Z',
      },
    ],
  },
  12: {
    width: 80,
    height: 75,
    paths: [
      {
        d: 'M62.3446 14.6974C61.16 14.6974 59.9785 14.8229 58.8216 15.0739C55.1277 6.48256 46.5077 0.751953 37.18 0.751953C25.5415 0.751953 15.8616 8.98512 13.9354 20.4219C13.9077 20.4203 13.88 20.4203 13.8539 20.4203C6.24309 20.4203 0.0507812 26.6407 0.0507812 34.2846C0.0507812 41.927 6.24309 48.1443 13.8539 48.1443H62.3446C71.5231 48.1443 78.9923 40.6412 78.9923 31.4208C78.9923 22.1989 71.5231 14.6974 62.3446 14.6974ZM75.6431 31.4208C75.6431 38.7953 69.6785 44.7938 62.3446 44.7938H13.8539C8.08924 44.7938 3.39847 40.0795 3.39847 34.2846C3.39847 28.4866 8.08924 23.7708 13.8539 23.7708C14.2385 23.7708 14.6585 23.8014 15.1769 23.8642L16.8769 24.077L17.0492 22.3703C18.0985 11.956 26.7539 4.10246 37.18 4.10246C45.7108 4.10246 53.3662 9.54226 56.2308 17.6377L56.7723 19.1652L58.3154 18.6739C59.6246 18.2576 60.98 18.0479 62.3446 18.0479C69.6785 18.0479 75.6431 24.0463 75.6431 31.4208Z',
      },
      {
        d: 'M14.0878 57.3676C15.497 59.9284 16.9078 62.4891 18.317 65.0498C19.2985 66.8314 22.0401 65.2411 21.057 63.4564C19.6462 60.8957 18.237 58.335 16.8262 55.7743C15.8447 53.9926 13.1047 55.5845 14.0878 57.3676Z',
      },
      {
        d: 'M29.803 57.3676C31.2138 59.9284 32.6246 62.4891 34.0338 65.0498C35.0153 66.8314 37.7569 65.2411 36.7738 63.4564C35.363 60.8957 33.9538 58.335 32.543 55.7743C31.5615 53.9926 28.8215 55.5845 29.803 57.3676Z',
      },
      {
        d: 'M46.323 57.3676C47.7338 59.9284 49.143 62.4891 50.5538 65.0498C51.5353 66.8314 54.2753 65.2411 53.2938 63.4564C51.883 60.8957 50.4723 58.335 49.063 55.7743C48.0815 53.9926 45.34 55.5845 46.323 57.3676Z',
      },
      {
        d: 'M62.2876 57.3676C63.6984 59.9284 65.1076 62.4891 66.5184 65.0498C67.4999 66.8314 70.2399 65.2411 69.2584 63.4564C67.8476 60.8957 66.4369 58.335 65.0276 55.7743C64.0461 53.9926 61.3046 55.5845 62.2876 57.3676Z',
      },
      {
        d: 'M27.003 66.4412C28.4122 69.0019 29.823 71.5626 31.2322 74.1233C32.2137 75.905 34.9553 74.3147 33.9722 72.53C32.563 69.9692 31.1522 67.4085 29.7414 64.8478C28.7614 63.0662 26.0199 64.6565 27.003 66.4412Z',
      },
      {
        d: 'M42.7168 66.436C44.1276 68.9967 45.5368 71.5574 46.9476 74.1181C47.9291 75.9013 50.6691 74.3095 49.6876 72.5248C48.2768 69.9641 46.8661 67.4033 45.4568 64.8426C44.4753 63.061 41.7338 64.6528 42.7168 66.436Z',
      },
      {
        d: 'M58.7477 65.8025C60.1569 68.3632 61.5677 70.9239 62.9769 73.4847C63.9585 75.2663 66.7 73.676 65.7169 71.8913C64.3062 69.3306 62.8969 66.7699 61.4862 64.2091C60.5046 62.4275 57.7646 64.0178 58.7477 65.8025Z',
      },
    ],
  },
  13: {
    width: 100,
    height: 70,
    paths: [
      {
        d: 'M84.0972 37.7396C83.0015 37.7396 81.9075 37.8596 80.8382 38.0946C77.419 30.023 69.4421 24.6396 60.8101 24.6396C50.0378 24.6396 41.0807 32.3746 39.2986 43.118C39.2722 43.118 39.2474 43.118 39.2227 43.118C32.1798 43.118 26.4487 48.9613 26.4487 56.1413C26.4487 63.3213 32.1798 69.163 39.2227 69.163H84.0972C92.5923 69.163 99.5032 62.1146 99.5032 53.4513C99.5032 44.788 92.5923 37.7396 84.0972 37.7396ZM96.4042 53.4513C96.4042 60.3797 90.8827 66.0146 84.0972 66.0146H39.2227C33.8877 66.0146 29.5477 61.5863 29.5477 56.1413C29.5477 50.6963 33.8877 46.2646 39.2227 46.2646C39.5791 46.2646 39.9669 46.293 40.4471 46.353L42.0213 46.553L42.1798 44.9496C43.1517 35.1646 51.1616 27.7863 60.8101 27.7863C68.7029 27.7863 75.7887 32.8963 78.4388 40.503L78.9405 41.938L80.3679 41.4763C81.5807 41.0863 82.8349 40.888 84.0972 40.888C90.8827 40.888 96.4042 46.523 96.4042 53.4513Z',
      },
      {
        d: 'M21.2771 22.2678C21.8447 22.8945 22.8167 22.9378 23.4371 22.3645C24.0576 21.7895 24.1005 20.8078 23.5329 20.1812L14.3942 10.1012C13.8266 9.47451 12.853 9.43118 12.2342 10.0062C11.6137 10.5795 11.5708 11.5612 12.1385 12.1878L21.2771 22.2678Z',
      },
      {
        d: 'M20.5856 47.5597L10.82 57.0213C10.2127 57.6097 10.1913 58.5913 10.7738 59.2047C11.3563 59.818 12.3299 59.838 12.9371 59.2497L22.7028 49.7897C23.3084 49.2013 23.3299 48.218 22.7474 47.6047C22.1649 46.993 21.1913 46.9713 20.5856 47.5597Z',
      },
      {
        d: 'M36.5048 17.827C37.3448 17.8803 38.0741 17.2303 38.1269 16.3836L38.9768 2.74198C39.0296 1.89365 38.386 1.15698 37.5477 1.10365C36.7078 1.05031 35.9784 1.70031 35.9256 2.54865L35.0758 16.1886C35.023 17.037 35.6665 17.7736 36.5048 17.827Z',
      },
      {
        d: 'M18.0609 34.1667C18.1269 33.3201 17.4932 32.5734 16.655 32.5067L3.16321 31.4501C2.32493 31.3834 1.58565 32.0234 1.51965 32.8701C1.45529 33.7168 2.0873 34.4634 2.92724 34.5301L16.419 35.5868C17.2573 35.6534 17.9965 35.0134 18.0609 34.1667Z',
      },
      {
        d: 'M41.9307 23.1263C40.2046 22.163 38.2591 21.2963 36.1666 21.1396C28.3713 20.553 21.5561 26.483 20.9769 34.3563C20.6023 39.443 22.929 44.1063 26.7195 46.9346C27.1336 46.193 27.6914 45.3813 28.4356 44.583C25.4191 42.3313 23.566 38.6213 23.8647 34.573C24.3267 28.308 29.7492 23.5896 35.9505 24.0563C37.1666 24.148 36.8366 23.833 37.9059 24.278L38.3201 24.4363C38.3201 24.4363 39.6204 23.088 41.9307 23.1263Z',
      },
      {
        d: 'M91.6764 33.1752C91.6764 26.2385 86.1434 20.5952 79.3415 20.5952C78.4636 20.5952 77.5873 20.6902 76.7309 20.8785C73.9949 14.4152 67.6071 10.1035 60.6946 10.1035C52.0708 10.1035 44.8976 16.2985 43.4702 24.9002C43.4504 24.9002 43.4289 24.9002 43.4091 24.9002C37.7705 24.9002 33.1814 29.5785 33.1814 35.3285C33.1814 37.0452 33.6022 38.6568 34.3266 40.0835C34.9058 39.7935 35.7177 39.5035 36.7639 39.3368C36.0758 38.1602 35.6632 36.7969 35.6632 35.3285C35.6632 30.9685 39.1385 27.4202 43.4091 27.4202C43.6946 27.4202 44.0065 27.4435 44.3893 27.4918L45.65 27.6502L45.7771 26.3668C46.556 18.5335 52.9685 12.6252 60.6946 12.6252C67.0147 12.6252 72.688 16.7168 74.8118 22.8068L75.2127 23.9568L76.3563 23.5868C77.3266 23.2735 78.3299 23.1152 79.3415 23.1152C84.7755 23.1152 89.1962 27.6285 89.1962 33.1752C89.1962 33.7402 89.1418 34.2918 89.0527 34.8318C89.7342 34.9935 90.5873 35.2752 91.4042 35.7502C91.5758 34.9185 91.6764 34.0585 91.6764 33.1752Z',
      },
    ],
  },
  14: {
    width: 99,
    height: 73,
    paths: [
      {
        d: 'M83.2153 41.4474C82.1608 41.4474 81.1079 41.5612 80.0787 41.787C76.7866 34.0592 69.1073 28.9033 60.7975 28.9033C50.4277 28.9033 41.8054 36.3103 40.0883 46.5965C40.0647 46.5965 40.041 46.5965 40.0157 46.5965C33.2354 46.5965 27.7192 52.1903 27.7192 59.0676C27.7192 65.9415 33.2354 71.5336 40.0157 71.5336H83.2153C91.3932 71.5336 98.0468 64.7854 98.0468 56.4905C98.0468 48.1957 91.3932 41.4474 83.2153 41.4474ZM95.0639 56.4905C95.0639 63.1233 89.7489 68.5202 83.2153 68.5202H40.0157C34.8798 68.5202 30.7021 64.2795 30.7021 59.0676C30.7021 53.8523 34.8798 49.6099 40.0157 49.6099C40.3587 49.6099 40.7339 49.637 41.1953 49.6947L42.7112 49.8849L42.8633 48.3502C43.7979 38.9824 51.5093 31.9184 60.7975 31.9184C68.3958 31.9184 75.2166 36.8094 77.7702 44.0924L78.2518 45.4675L79.6258 45.0244C80.7936 44.6509 82.0002 44.4625 83.2153 44.4625C89.7489 44.4625 95.0639 49.8577 95.0639 56.4905Z',
      },
      {
        d: 'M22.3957 22.1937C22.9855 22.8592 24.0096 22.9203 24.6721 22.3295C25.3363 21.737 25.3971 20.7082 24.8073 20.0427L15.3315 9.32191C14.7434 8.65642 13.7192 8.59531 13.0551 9.18779C12.3926 9.77858 12.3318 10.8074 12.9199 11.4746L22.3957 22.1937Z',
      },
      {
        d: 'M21.2634 48.7066L10.8158 58.4716C10.1668 59.0794 10.1297 60.1082 10.733 60.7618C11.338 61.4137 12.3638 61.4511 13.0128 60.8433L23.4604 51.0783C24.1093 50.4722 24.1465 49.4417 23.5432 48.7898C22.9381 48.1379 21.9123 48.1006 21.2634 48.7066Z',
      },
      {
        d: 'M63.2177 11.4666C62.6127 10.8147 61.5869 10.7773 60.9379 11.3834L50.4904 21.1484C49.8414 21.7562 49.8042 22.785 50.4092 23.4386C51.0126 24.0905 52.0384 24.1278 52.6874 23.5201L63.1349 13.755C63.7839 13.149 63.8211 12.1185 63.2177 11.4666Z',
      },
      {
        d: 'M38.5252 17.7815C39.409 17.8512 40.1881 17.1806 40.2574 16.2927L41.3711 2.00166C41.4404 1.11378 40.7729 0.329452 39.889 0.259848C39.0051 0.190243 38.2243 0.860824 38.1551 1.74871L37.043 16.0397C36.9737 16.9293 37.6396 17.7119 38.5252 17.7815Z',
      },
      {
        d: 'M18.8162 34.6191C18.8973 33.7329 18.2416 32.9401 17.3594 32.8569L3.14995 31.5293C2.26608 31.4478 1.47685 32.1048 1.39573 32.9927C1.31292 33.8806 1.96864 34.6734 2.85082 34.7549L17.0603 36.0825C17.9442 36.1656 18.7334 35.5069 18.8162 34.6191Z',
      },
      {
        d: 'M24.9291 35.1406C25.5155 28.5774 31.3071 23.7169 37.8406 24.306C41.1412 24.6048 44.0057 26.238 45.9594 28.6266C46.6709 27.9238 47.4466 27.2549 48.2882 26.6403C45.8326 23.6592 42.2431 21.622 38.1127 21.2502C29.9027 20.51 22.6239 26.6183 21.8871 34.8656C21.4105 40.1963 23.79 45.1229 27.7429 48.1499C28.1891 47.3791 28.7907 46.5371 29.5901 45.712C26.445 43.303 24.5505 39.3814 24.9291 35.1406Z',
      },
    ],
  },
  15: {
    width: 94,
    height: 83,
    paths: [
      {
        d: 'M17.7887 33.0433H11.3363C7.13049 33.0433 3.70755 29.5396 3.70755 25.2331C3.70755 20.9253 7.13049 17.4216 11.3363 17.4216C11.617 17.4216 11.9231 17.4434 12.3018 17.491L13.5426 17.6488L13.6675 16.3807C14.4343 8.64128 20.7484 2.80543 28.357 2.80543C31.6605 2.80543 34.7786 3.95382 37.2951 5.92269C37.839 5.29679 38.4083 4.70354 39.0099 4.15248C36.0207 1.73459 32.2768 0.31543 28.357 0.31543C19.8635 0.31543 12.8014 6.4343 11.3954 14.9316C11.3753 14.9316 11.3565 14.9316 11.3363 14.9316C5.78361 14.9316 1.26489 19.5537 1.26489 25.2331C1.26489 30.9125 5.78361 35.5333 11.3363 35.5333H17.8451C17.7874 35.0176 17.7485 34.4965 17.7485 33.9631C17.7485 33.6529 17.7686 33.3481 17.7887 33.0433Z',
      },
      {
        d: 'M77.9932 14.9582C76.9108 14.9582 75.8298 15.0765 74.773 15.3106C71.3944 7.30856 63.5118 1.9707 54.982 1.9707C44.3385 1.9707 35.4877 9.63936 33.7245 20.2892C33.7004 20.2892 33.6749 20.2892 33.6507 20.2892C26.6907 20.2892 21.0278 26.0815 21.0278 33.2005C21.0278 40.3194 26.6907 46.109 33.6507 46.109H77.9932C86.3874 46.109 93.2171 39.122 93.2171 30.5336C93.2171 21.9451 86.3874 14.9582 77.9932 14.9582ZM90.1554 30.5336C90.1554 37.4022 84.7007 42.989 77.9932 42.989H33.6507C28.3786 42.989 24.0895 38.5982 24.0895 33.2005C24.0895 27.8014 28.3786 23.4092 33.6507 23.4092C34.0025 23.4092 34.3866 23.4378 34.8593 23.4963L36.4156 23.6949L36.5714 22.1057C37.5329 12.4056 45.4477 5.09069 54.982 5.09069C62.7826 5.09069 69.7829 10.1564 72.4029 17.6972L72.8984 19.1204L74.3097 18.6619C75.5075 18.2754 76.747 18.0795 77.9932 18.0795C84.7007 18.0795 90.1554 23.6664 90.1554 30.5336Z',
      },
      {
        d: 'M33.8643 54.7001C35.1534 57.0839 36.4426 59.4692 37.7331 61.8544C38.6301 63.5144 41.1359 62.0327 40.2375 60.3699C38.9483 57.9861 37.6592 55.6008 36.3687 53.2156C35.4717 51.557 32.9659 53.0387 33.8643 54.7001Z',
      },
      {
        d: 'M77.9422 54.7001C79.2313 57.0839 80.5218 59.4692 81.8109 61.8544C82.708 63.5144 85.2151 62.0327 84.3167 60.3699C83.0262 57.9861 81.7371 55.6008 80.4479 53.2156C79.5496 51.557 77.0438 53.0387 77.9422 54.7001Z',
      },
      {
        d: 'M74.7044 62.5551C75.9935 64.9403 77.2827 67.3256 78.5731 69.7108C79.4702 71.3694 81.9759 69.8877 81.0776 68.2263C79.7884 65.8411 78.4993 63.4572 77.2088 61.072C76.3118 59.412 73.806 60.8937 74.7044 62.5551Z',
      },
      {
        d: 'M11.9353 45.1821C13.2257 47.5673 14.5149 49.9525 15.804 52.3377C16.7011 53.9964 19.2082 52.5146 18.3098 50.8533C17.0207 48.468 15.7302 46.0842 14.441 43.6989C13.544 42.0389 11.0369 43.5207 11.9353 45.1821Z',
      },
      {
        d: 'M23.7471 53.6333C25.0362 56.0185 26.3254 58.4024 27.6159 60.7876C28.5129 62.4476 31.0187 60.9659 30.1203 59.3045C28.8312 56.9193 27.542 54.5341 26.2515 52.1488C25.3545 50.4888 22.8487 51.9719 23.7471 53.6333Z',
      },
      {
        d: 'M20.3684 63.1498C21.6576 65.5351 22.9467 67.9203 24.2372 70.3055C25.1342 71.9642 27.6413 70.4824 26.7416 68.821C25.4525 66.4358 24.1633 64.0506 22.8742 61.6667C21.9758 60.0067 19.4701 61.4885 20.3684 63.1498Z',
      },
      {
        d: 'M33.8643 64.7905C35.1534 67.1744 36.4426 69.5596 37.7331 71.9449C38.6301 73.6049 41.1359 72.1231 40.2375 70.4604C38.9483 68.0765 37.6592 65.6913 36.3687 63.306C35.4717 61.646 32.9659 63.1292 33.8643 64.7905Z',
      },
      {
        d: 'M61.856 45.9629L61.5471 46.1085H61.9245L61.856 45.9629Z',
      },
      {
        d: 'M47.8582 45.8311L47.4258 46.1086H48.0328L47.8582 45.8311Z',
      },
      {
        d: 'M67.9728 64.6951H63.2996C62.6806 64.6951 62.1058 65.0066 61.7621 65.5305C61.4196 66.053 61.3552 66.7115 61.5942 67.2912L65.9813 77.9233L48.1173 58.7286H52.589C53.2631 58.7286 53.8849 58.3558 54.2085 57.7571C54.5335 57.157 54.5106 56.4277 54.1481 55.8494L49.7314 48.8135H46.9799L51.7269 56.376H47.0229C46.2843 56.376 45.6169 56.8209 45.3255 57.5067C45.0328 58.1938 45.1711 58.9898 45.6773 59.5341L66.0726 81.4488C66.4379 81.842 66.92 82.0448 67.4115 82.0448C67.7579 82.0448 68.1084 81.9441 68.424 81.7359C69.1854 81.2352 69.4741 80.2922 69.1236 79.4418L64.01 67.0476H68.7167C69.3532 67.0476 69.9374 66.7211 70.2785 66.1768C70.6182 65.6325 70.6612 64.959 70.3899 64.378L63.1774 48.8135H60.6139L67.9728 64.6951Z',
      },
    ],
  },
  16: {
    width: 100,
    height: 70,
    paths: [
      {
        d: 'M84.0972 37.7396C83.0015 37.7396 81.9075 37.8596 80.8382 38.0946C77.419 30.023 69.4421 24.6396 60.8101 24.6396C50.0378 24.6396 41.0807 32.3746 39.2986 43.118C39.2722 43.118 39.2474 43.118 39.2227 43.118C32.1798 43.118 26.4487 48.9613 26.4487 56.1413C26.4487 63.3213 32.1798 69.163 39.2227 69.163H84.0972C92.5923 69.163 99.5032 62.1146 99.5032 53.4513C99.5032 44.788 92.5923 37.7396 84.0972 37.7396ZM96.4042 53.4513C96.4042 60.3797 90.8827 66.0146 84.0972 66.0146H39.2227C33.8877 66.0146 29.5477 61.5863 29.5477 56.1413C29.5477 50.6963 33.8877 46.2646 39.2227 46.2646C39.5791 46.2646 39.9669 46.293 40.4471 46.353L42.0213 46.553L42.1798 44.9496C43.1517 35.1646 51.1616 27.7863 60.8101 27.7863C68.7029 27.7863 75.7887 32.8963 78.4388 40.503L78.9405 41.938L80.3679 41.4763C81.5807 41.0863 82.8349 40.888 84.0972 40.888C90.8827 40.888 96.4042 46.523 96.4042 53.4513Z',
      },
      {
        d: 'M21.2771 22.2678C21.8447 22.8945 22.8167 22.9378 23.4371 22.3645C24.0576 21.7895 24.1005 20.8078 23.5329 20.1812L14.3942 10.1012C13.8266 9.47451 12.853 9.43118 12.2342 10.0062C11.6137 10.5795 11.5708 11.5612 12.1385 12.1878L21.2771 22.2678Z',
      },
      {
        d: 'M20.5856 47.5597L10.82 57.0213C10.2127 57.6097 10.1913 58.5913 10.7738 59.2047C11.3563 59.818 12.3299 59.838 12.9371 59.2497L22.7028 49.7897C23.3084 49.2013 23.3299 48.218 22.7474 47.6047C22.1649 46.993 21.1913 46.9713 20.5856 47.5597Z',
      },
      {
        d: 'M36.5048 17.827C37.3448 17.8803 38.0741 17.2303 38.1269 16.3836L38.9768 2.74198C39.0296 1.89365 38.386 1.15698 37.5477 1.10365C36.7078 1.05031 35.9784 1.70031 35.9256 2.54865L35.0758 16.1886C35.023 17.037 35.6665 17.7736 36.5048 17.827Z',
      },
      {
        d: 'M18.0609 34.1667C18.1269 33.3201 17.4932 32.5734 16.655 32.5067L3.16321 31.4501C2.32493 31.3834 1.58565 32.0234 1.51965 32.8701C1.45529 33.7168 2.0873 34.4634 2.92724 34.5301L16.419 35.5868C17.2573 35.6534 17.9965 35.0134 18.0609 34.1667Z',
      },
      {
        d: 'M41.9307 23.1263C40.2046 22.163 38.2591 21.2963 36.1666 21.1396C28.3713 20.553 21.5561 26.483 20.9769 34.3563C20.6023 39.443 22.929 44.1063 26.7195 46.9346C27.1336 46.193 27.6914 45.3813 28.4356 44.583C25.4191 42.3313 23.566 38.6213 23.8647 34.573C24.3267 28.308 29.7492 23.5896 35.9505 24.0563C37.1666 24.148 36.8366 23.833 37.9059 24.278L38.3201 24.4363C38.3201 24.4363 39.6204 23.088 41.9307 23.1263Z',
      },
      {
        d: 'M91.6764 33.1752C91.6764 26.2385 86.1434 20.5952 79.3415 20.5952C78.4636 20.5952 77.5873 20.6902 76.7309 20.8785C73.9949 14.4152 67.6071 10.1035 60.6946 10.1035C52.0708 10.1035 44.8976 16.2985 43.4702 24.9002C43.4504 24.9002 43.4289 24.9002 43.4091 24.9002C37.7705 24.9002 33.1814 29.5785 33.1814 35.3285C33.1814 37.0452 33.6022 38.6568 34.3266 40.0835C34.9058 39.7935 35.7177 39.5035 36.7639 39.3368C36.0758 38.1602 35.6632 36.7969 35.6632 35.3285C35.6632 30.9685 39.1385 27.4202 43.4091 27.4202C43.6946 27.4202 44.0065 27.4435 44.3893 27.4918L45.65 27.6502L45.7771 26.3668C46.556 18.5335 52.9685 12.6252 60.6946 12.6252C67.0147 12.6252 72.688 16.7168 74.8118 22.8068L75.2127 23.9568L76.3563 23.5868C77.3266 23.2735 78.3299 23.1152 79.3415 23.1152C84.7755 23.1152 89.1962 27.6285 89.1962 33.1752C89.1962 33.7402 89.1418 34.2918 89.0527 34.8318C89.7342 34.9935 90.5873 35.2752 91.4042 35.7502C91.5758 34.9185 91.6764 34.0585 91.6764 33.1752Z',
      },
    ],
  },
  17: {
    width: 99,
    height: 73,
    paths: [
      {
        d: 'M83.2153 41.4474C82.1608 41.4474 81.1079 41.5612 80.0787 41.787C76.7866 34.0592 69.1073 28.9033 60.7975 28.9033C50.4277 28.9033 41.8054 36.3103 40.0883 46.5965C40.0647 46.5965 40.041 46.5965 40.0157 46.5965C33.2354 46.5965 27.7192 52.1903 27.7192 59.0676C27.7192 65.9415 33.2354 71.5336 40.0157 71.5336H83.2153C91.3932 71.5336 98.0468 64.7854 98.0468 56.4905C98.0468 48.1957 91.3932 41.4474 83.2153 41.4474ZM95.0639 56.4905C95.0639 63.1233 89.7489 68.5202 83.2153 68.5202H40.0157C34.8798 68.5202 30.7021 64.2795 30.7021 59.0676C30.7021 53.8523 34.8798 49.6099 40.0157 49.6099C40.3587 49.6099 40.7339 49.637 41.1953 49.6947L42.7112 49.8849L42.8633 48.3502C43.7979 38.9824 51.5093 31.9184 60.7975 31.9184C68.3958 31.9184 75.2166 36.8094 77.7702 44.0924L78.2518 45.4675L79.6258 45.0244C80.7936 44.6509 82.0002 44.4625 83.2153 44.4625C89.7489 44.4625 95.0639 49.8577 95.0639 56.4905Z',
      },
      {
        d: 'M22.3957 22.1937C22.9855 22.8592 24.0096 22.9203 24.6721 22.3295C25.3363 21.737 25.3971 20.7082 24.8073 20.0427L15.3315 9.32191C14.7434 8.65642 13.7192 8.59531 13.0551 9.18779C12.3926 9.77858 12.3318 10.8074 12.9199 11.4746L22.3957 22.1937Z',
      },
      {
        d: 'M21.2634 48.7066L10.8158 58.4716C10.1668 59.0794 10.1297 60.1082 10.733 60.7618C11.338 61.4137 12.3638 61.4511 13.0128 60.8433L23.4604 51.0783C24.1093 50.4722 24.1465 49.4417 23.5432 48.7898C22.9381 48.1379 21.9123 48.1006 21.2634 48.7066Z',
      },
      {
        d: 'M63.2177 11.4666C62.6127 10.8147 61.5869 10.7773 60.9379 11.3834L50.4904 21.1484C49.8414 21.7562 49.8042 22.785 50.4092 23.4386C51.0126 24.0905 52.0384 24.1278 52.6874 23.5201L63.1349 13.755C63.7839 13.149 63.8211 12.1185 63.2177 11.4666Z',
      },
      {
        d: 'M38.5252 17.7815C39.409 17.8512 40.1881 17.1806 40.2574 16.2927L41.3711 2.00166C41.4404 1.11378 40.7729 0.329452 39.889 0.259848C39.0051 0.190243 38.2243 0.860824 38.1551 1.74871L37.043 16.0397C36.9737 16.9293 37.6396 17.7119 38.5252 17.7815Z',
      },
      {
        d: 'M18.8162 34.6191C18.8973 33.7329 18.2416 32.9401 17.3594 32.8569L3.14995 31.5293C2.26608 31.4478 1.47685 32.1048 1.39573 32.9927C1.31292 33.8806 1.96864 34.6734 2.85082 34.7549L17.0603 36.0825C17.9442 36.1656 18.7334 35.5069 18.8162 34.6191Z',
      },
      {
        d: 'M24.9291 35.1406C25.5155 28.5774 31.3071 23.7169 37.8406 24.306C41.1412 24.6048 44.0057 26.238 45.9594 28.6266C46.6709 27.9238 47.4466 27.2549 48.2882 26.6403C45.8326 23.6592 42.2431 21.622 38.1127 21.2502C29.9027 20.51 22.6239 26.6183 21.8871 34.8656C21.4105 40.1963 23.79 45.1229 27.7429 48.1499C28.1891 47.3791 28.7907 46.5371 29.5901 45.712C26.445 43.303 24.5505 39.3814 24.9291 35.1406Z',
      },
    ],
  },
  18: {
    width: 80,
    height: 75,
    paths: [
      {
        d: 'M62.3446 14.6974C61.16 14.6974 59.9785 14.8229 58.8216 15.0739C55.1277 6.48256 46.5077 0.751953 37.18 0.751953C25.5415 0.751953 15.8616 8.98512 13.9354 20.4219C13.9077 20.4203 13.88 20.4203 13.8539 20.4203C6.24309 20.4203 0.0507812 26.6407 0.0507812 34.2846C0.0507812 41.927 6.24309 48.1443 13.8539 48.1443H62.3446C71.5231 48.1443 78.9923 40.6412 78.9923 31.4208C78.9923 22.1989 71.5231 14.6974 62.3446 14.6974ZM75.6431 31.4208C75.6431 38.7953 69.6785 44.7938 62.3446 44.7938H13.8539C8.08924 44.7938 3.39847 40.0795 3.39847 34.2846C3.39847 28.4866 8.08924 23.7708 13.8539 23.7708C14.2385 23.7708 14.6585 23.8014 15.1769 23.8642L16.8769 24.077L17.0492 22.3703C18.0985 11.956 26.7539 4.10246 37.18 4.10246C45.7108 4.10246 53.3662 9.54226 56.2308 17.6377L56.7723 19.1652L58.3154 18.6739C59.6246 18.2576 60.98 18.0479 62.3446 18.0479C69.6785 18.0479 75.6431 24.0463 75.6431 31.4208Z',
      },
      {
        d: 'M14.0878 57.3676C15.497 59.9284 16.9078 62.4891 18.317 65.0498C19.2985 66.8314 22.0401 65.2411 21.057 63.4564C19.6462 60.8957 18.237 58.335 16.8262 55.7743C15.8447 53.9926 13.1047 55.5845 14.0878 57.3676Z',
      },
      {
        d: 'M29.803 57.3676C31.2138 59.9284 32.6246 62.4891 34.0338 65.0498C35.0153 66.8314 37.7569 65.2411 36.7738 63.4564C35.363 60.8957 33.9538 58.335 32.543 55.7743C31.5615 53.9926 28.8215 55.5845 29.803 57.3676Z',
      },
      {
        d: 'M46.323 57.3676C47.7338 59.9284 49.143 62.4891 50.5538 65.0498C51.5353 66.8314 54.2753 65.2411 53.2938 63.4564C51.883 60.8957 50.4723 58.335 49.063 55.7743C48.0815 53.9926 45.34 55.5845 46.323 57.3676Z',
      },
      {
        d: 'M62.2876 57.3676C63.6984 59.9284 65.1076 62.4891 66.5184 65.0498C67.4999 66.8314 70.2399 65.2411 69.2584 63.4564C67.8476 60.8957 66.4369 58.335 65.0276 55.7743C64.0461 53.9926 61.3046 55.5845 62.2876 57.3676Z',
      },
      {
        d: 'M27.003 66.4412C28.4122 69.0019 29.823 71.5626 31.2322 74.1233C32.2137 75.905 34.9553 74.3147 33.9722 72.53C32.563 69.9692 31.1522 67.4085 29.7414 64.8478C28.7614 63.0662 26.0199 64.6565 27.003 66.4412Z',
      },
      {
        d: 'M42.7168 66.436C44.1276 68.9967 45.5368 71.5574 46.9476 74.1181C47.9291 75.9013 50.6691 74.3095 49.6876 72.5248C48.2768 69.9641 46.8661 67.4033 45.4568 64.8426C44.4753 63.061 41.7338 64.6528 42.7168 66.436Z',
      },
      {
        d: 'M58.7477 65.8025C60.1569 68.3632 61.5677 70.9239 62.9769 73.4847C63.9585 75.2663 66.7 73.676 65.7169 71.8913C64.3062 69.3306 62.8969 66.7699 61.4862 64.2091C60.5046 62.4275 57.7646 64.0178 58.7477 65.8025Z',
      },
    ],
  },
  19: {
    width: 63,
    height: 72,
    paths: [
      {
        d: 'M58.0313 43.4069L57.6099 40.4046L44.7915 42.276L34.4741 36.2036L44.7899 30.1328L57.6099 32.0042L58.0313 29.0035L48.962 27.6774L63 19.4158L61.5134 16.7894L47.4738 25.0526L50.8843 16.3812L48.1225 15.2536L43.3033 27.5064L33.2259 33.4368V21.4356L41.2251 11.0542L38.8863 9.17956L33.2259 16.5264V0H30.2511V16.5264L24.5891 9.17956L22.2518 11.0542L30.2511 21.4356V33.7175L19.6967 27.5064L14.8759 15.2536L12.1141 16.3828L15.5246 25.0526L1.48664 16.7894L0 19.4158L14.038 27.6774L4.96711 29.0035L5.39005 32.0042L18.2085 30.1328L28.5259 36.2036L18.2069 42.276L5.39005 40.4046L4.96711 43.4069L14.038 44.7314L0 52.993L1.48664 55.6194L15.5262 47.3562L12.1141 56.0276L14.8759 57.1552L19.6952 44.9024L30.2511 38.6913V50.9248L22.2518 61.3094L24.5907 63.1808L30.2511 55.8356V71.394H33.2259V55.8356L38.8863 63.1808L41.2251 61.3094L33.2259 50.9264V38.972L43.3033 44.9024L48.1225 57.1552L50.8843 56.0276L47.4738 47.3562L61.5134 55.6194L63 52.993L48.962 44.7314L58.0313 43.4069Z',
      },
    ],
  },
  20: {
    width: 100,
    height: 70,
    paths: [
      {
        d: 'M84.0972 37.7396C83.0015 37.7396 81.9075 37.8596 80.8382 38.0946C77.419 30.023 69.4421 24.6396 60.8101 24.6396C50.0378 24.6396 41.0807 32.3746 39.2986 43.118C39.2722 43.118 39.2474 43.118 39.2227 43.118C32.1798 43.118 26.4487 48.9613 26.4487 56.1413C26.4487 63.3213 32.1798 69.163 39.2227 69.163H84.0972C92.5923 69.163 99.5032 62.1146 99.5032 53.4513C99.5032 44.788 92.5923 37.7396 84.0972 37.7396ZM96.4042 53.4513C96.4042 60.3797 90.8827 66.0146 84.0972 66.0146H39.2227C33.8877 66.0146 29.5477 61.5863 29.5477 56.1413C29.5477 50.6963 33.8877 46.2646 39.2227 46.2646C39.5791 46.2646 39.9669 46.293 40.4471 46.353L42.0213 46.553L42.1798 44.9496C43.1517 35.1646 51.1616 27.7863 60.8101 27.7863C68.7029 27.7863 75.7887 32.8963 78.4388 40.503L78.9405 41.938L80.3679 41.4763C81.5807 41.0863 82.8349 40.888 84.0972 40.888C90.8827 40.888 96.4042 46.523 96.4042 53.4513Z',
      },
      {
        d: 'M21.2771 22.2678C21.8447 22.8945 22.8167 22.9378 23.4371 22.3645C24.0576 21.7895 24.1005 20.8078 23.5329 20.1812L14.3942 10.1012C13.8266 9.47451 12.853 9.43118 12.2342 10.0062C11.6137 10.5795 11.5708 11.5612 12.1385 12.1878L21.2771 22.2678Z',
      },
      {
        d: 'M20.5856 47.5597L10.82 57.0213C10.2127 57.6097 10.1913 58.5913 10.7738 59.2047C11.3563 59.818 12.3299 59.838 12.9371 59.2497L22.7028 49.7897C23.3084 49.2013 23.3299 48.218 22.7474 47.6047C22.1649 46.993 21.1913 46.9713 20.5856 47.5597Z',
      },
      {
        d: 'M36.5048 17.827C37.3448 17.8803 38.0741 17.2303 38.1269 16.3836L38.9768 2.74198C39.0296 1.89365 38.386 1.15698 37.5477 1.10365C36.7078 1.05031 35.9784 1.70031 35.9256 2.54865L35.0758 16.1886C35.023 17.037 35.6665 17.7736 36.5048 17.827Z',
      },
      {
        d: 'M18.0609 34.1667C18.1269 33.3201 17.4932 32.5734 16.655 32.5067L3.16321 31.4501C2.32493 31.3834 1.58565 32.0234 1.51965 32.8701C1.45529 33.7168 2.0873 34.4634 2.92724 34.5301L16.419 35.5868C17.2573 35.6534 17.9965 35.0134 18.0609 34.1667Z',
      },
      {
        d: 'M41.9307 23.1263C40.2046 22.163 38.2591 21.2963 36.1666 21.1396C28.3713 20.553 21.5561 26.483 20.9769 34.3563C20.6023 39.443 22.929 44.1063 26.7195 46.9346C27.1336 46.193 27.6914 45.3813 28.4356 44.583C25.4191 42.3313 23.566 38.6213 23.8647 34.573C24.3267 28.308 29.7492 23.5896 35.9505 24.0563C37.1666 24.148 36.8366 23.833 37.9059 24.278L38.3201 24.4363C38.3201 24.4363 39.6204 23.088 41.9307 23.1263Z',
      },
      {
        d: 'M91.6764 33.1752C91.6764 26.2385 86.1434 20.5952 79.3415 20.5952C78.4636 20.5952 77.5873 20.6902 76.7309 20.8785C73.9949 14.4152 67.6071 10.1035 60.6946 10.1035C52.0708 10.1035 44.8976 16.2985 43.4702 24.9002C43.4504 24.9002 43.4289 24.9002 43.4091 24.9002C37.7705 24.9002 33.1814 29.5785 33.1814 35.3285C33.1814 37.0452 33.6022 38.6568 34.3266 40.0835C34.9058 39.7935 35.7177 39.5035 36.7639 39.3368C36.0758 38.1602 35.6632 36.7969 35.6632 35.3285C35.6632 30.9685 39.1385 27.4202 43.4091 27.4202C43.6946 27.4202 44.0065 27.4435 44.3893 27.4918L45.65 27.6502L45.7771 26.3668C46.556 18.5335 52.9685 12.6252 60.6946 12.6252C67.0147 12.6252 72.688 16.7168 74.8118 22.8068L75.2127 23.9568L76.3563 23.5868C77.3266 23.2735 78.3299 23.1152 79.3415 23.1152C84.7755 23.1152 89.1962 27.6285 89.1962 33.1752C89.1962 33.7402 89.1418 34.2918 89.0527 34.8318C89.7342 34.9935 90.5873 35.2752 91.4042 35.7502C91.5758 34.9185 91.6764 34.0585 91.6764 33.1752Z',
      },
    ],
  },
  21: {
    width: 99,
    height: 73,
    paths: [
      {
        d: 'M83.2153 41.4474C82.1608 41.4474 81.1079 41.5612 80.0787 41.787C76.7866 34.0592 69.1073 28.9033 60.7975 28.9033C50.4277 28.9033 41.8054 36.3103 40.0883 46.5965C40.0647 46.5965 40.041 46.5965 40.0157 46.5965C33.2354 46.5965 27.7192 52.1903 27.7192 59.0676C27.7192 65.9415 33.2354 71.5336 40.0157 71.5336H83.2153C91.3932 71.5336 98.0468 64.7854 98.0468 56.4905C98.0468 48.1957 91.3932 41.4474 83.2153 41.4474ZM95.0639 56.4905C95.0639 63.1233 89.7489 68.5202 83.2153 68.5202H40.0157C34.8798 68.5202 30.7021 64.2795 30.7021 59.0676C30.7021 53.8523 34.8798 49.6099 40.0157 49.6099C40.3587 49.6099 40.7339 49.637 41.1953 49.6947L42.7112 49.8849L42.8633 48.3502C43.7979 38.9824 51.5093 31.9184 60.7975 31.9184C68.3958 31.9184 75.2166 36.8094 77.7702 44.0924L78.2518 45.4675L79.6258 45.0244C80.7936 44.6509 82.0002 44.4625 83.2153 44.4625C89.7489 44.4625 95.0639 49.8577 95.0639 56.4905Z',
      },
      {
        d: 'M22.3957 22.1937C22.9855 22.8592 24.0096 22.9203 24.6721 22.3295C25.3363 21.737 25.3971 20.7082 24.8073 20.0427L15.3315 9.32191C14.7434 8.65642 13.7192 8.59531 13.0551 9.18779C12.3926 9.77858 12.3318 10.8074 12.9199 11.4746L22.3957 22.1937Z',
      },
      {
        d: 'M21.2634 48.7066L10.8158 58.4716C10.1668 59.0794 10.1297 60.1082 10.733 60.7618C11.338 61.4137 12.3638 61.4511 13.0128 60.8433L23.4604 51.0783C24.1093 50.4722 24.1465 49.4417 23.5432 48.7898C22.9381 48.1379 21.9123 48.1006 21.2634 48.7066Z',
      },
      {
        d: 'M63.2177 11.4666C62.6127 10.8147 61.5869 10.7773 60.9379 11.3834L50.4904 21.1484C49.8414 21.7562 49.8042 22.785 50.4092 23.4386C51.0126 24.0905 52.0384 24.1278 52.6874 23.5201L63.1349 13.755C63.7839 13.149 63.8211 12.1185 63.2177 11.4666Z',
      },
      {
        d: 'M38.5252 17.7815C39.409 17.8512 40.1881 17.1806 40.2574 16.2927L41.3711 2.00166C41.4404 1.11378 40.7729 0.329452 39.889 0.259848C39.0051 0.190243 38.2243 0.860824 38.1551 1.74871L37.043 16.0397C36.9737 16.9293 37.6396 17.7119 38.5252 17.7815Z',
      },
      {
        d: 'M18.8162 34.6191C18.8973 33.7329 18.2416 32.9401 17.3594 32.8569L3.14995 31.5293C2.26608 31.4478 1.47685 32.1048 1.39573 32.9927C1.31292 33.8806 1.96864 34.6734 2.85082 34.7549L17.0603 36.0825C17.9442 36.1656 18.7334 35.5069 18.8162 34.6191Z',
      },
      {
        d: 'M24.9291 35.1406C25.5155 28.5774 31.3071 23.7169 37.8406 24.306C41.1412 24.6048 44.0057 26.238 45.9594 28.6266C46.6709 27.9238 47.4466 27.2549 48.2882 26.6403C45.8326 23.6592 42.2431 21.622 38.1127 21.2502C29.9027 20.51 22.6239 26.6183 21.8871 34.8656C21.4105 40.1963 23.79 45.1229 27.7429 48.1499C28.1891 47.3791 28.7907 46.5371 29.5901 45.712C26.445 43.303 24.5505 39.3814 24.9291 35.1406Z',
      },
    ],
  },
  22: {
    width: 66,
    height: 75,
    paths: [
      {
        d: 'M60.765 45.4516L60.3238 42.3079L46.9015 44.2675L36.098 37.909L46.8998 31.5523L60.3238 33.5118L60.765 30.3698L51.2685 28.9812L65.9677 20.3304L64.4111 17.5803L49.7101 26.2328L53.2813 17.1529L50.3894 15.9721L45.3431 28.8021L34.7911 35.0119V22.4454L43.1671 11.5749L40.7181 9.61198L34.7911 17.3049V0H31.6761V17.3049L25.7474 9.61198L23.3001 11.5749L31.6761 22.4454V35.3059L20.6246 28.8021L15.5767 15.9721L12.6848 17.1546L16.2559 26.2328L1.55667 17.5803L0 20.3304L14.6993 28.9812L5.2011 30.3698L5.64396 33.5118L19.0663 31.5523L29.8697 37.909L19.0646 44.2675L5.64396 42.3079L5.2011 45.4516L14.6993 46.8385L0 55.4893L1.55667 58.2395L16.2576 49.587L12.6848 58.6668L15.5767 59.8476L20.6229 47.0176L31.6761 40.5139V53.3237L23.3001 64.1975L25.7491 66.1571L31.6761 58.4658V74.7572H34.7911V58.4658L40.7181 66.1571L43.1671 64.1975L34.7911 53.3254V40.8078L45.3431 47.0176L50.3894 59.8476L53.2813 58.6668L49.7101 49.587L64.4111 58.2395L65.9677 55.4893L51.2685 46.8385L60.765 45.4516Z',
      },
    ],
  },
  23: {
    width: 100,
    height: 70,
    paths: [
      {
        d: 'M84.0972 37.7396C83.0015 37.7396 81.9075 37.8596 80.8382 38.0946C77.419 30.023 69.4421 24.6396 60.8101 24.6396C50.0378 24.6396 41.0807 32.3746 39.2986 43.118C39.2722 43.118 39.2474 43.118 39.2227 43.118C32.1798 43.118 26.4487 48.9613 26.4487 56.1413C26.4487 63.3213 32.1798 69.163 39.2227 69.163H84.0972C92.5923 69.163 99.5032 62.1146 99.5032 53.4513C99.5032 44.788 92.5923 37.7396 84.0972 37.7396ZM96.4042 53.4513C96.4042 60.3797 90.8827 66.0146 84.0972 66.0146H39.2227C33.8877 66.0146 29.5477 61.5863 29.5477 56.1413C29.5477 50.6963 33.8877 46.2646 39.2227 46.2646C39.5791 46.2646 39.9669 46.293 40.4471 46.353L42.0213 46.553L42.1798 44.9496C43.1517 35.1646 51.1616 27.7863 60.8101 27.7863C68.7029 27.7863 75.7887 32.8963 78.4388 40.503L78.9405 41.938L80.3679 41.4763C81.5807 41.0863 82.8349 40.888 84.0972 40.888C90.8827 40.888 96.4042 46.523 96.4042 53.4513Z',
      },
      {
        d: 'M21.2771 22.2678C21.8447 22.8945 22.8167 22.9378 23.4371 22.3645C24.0576 21.7895 24.1005 20.8078 23.5329 20.1812L14.3942 10.1012C13.8266 9.47451 12.853 9.43118 12.2342 10.0062C11.6137 10.5795 11.5708 11.5612 12.1385 12.1878L21.2771 22.2678Z',
      },
      {
        d: 'M20.5856 47.5597L10.82 57.0213C10.2127 57.6097 10.1913 58.5913 10.7738 59.2047C11.3563 59.818 12.3299 59.838 12.9371 59.2497L22.7028 49.7897C23.3084 49.2013 23.3299 48.218 22.7474 47.6047C22.1649 46.993 21.1913 46.9713 20.5856 47.5597Z',
      },
      {
        d: 'M36.5048 17.827C37.3448 17.8803 38.0741 17.2303 38.1269 16.3836L38.9768 2.74198C39.0296 1.89365 38.386 1.15698 37.5477 1.10365C36.7078 1.05031 35.9784 1.70031 35.9256 2.54865L35.0758 16.1886C35.023 17.037 35.6665 17.7736 36.5048 17.827Z',
      },
      {
        d: 'M18.0609 34.1667C18.1269 33.3201 17.4932 32.5734 16.655 32.5067L3.16321 31.4501C2.32493 31.3834 1.58565 32.0234 1.51965 32.8701C1.45529 33.7168 2.0873 34.4634 2.92724 34.5301L16.419 35.5868C17.2573 35.6534 17.9965 35.0134 18.0609 34.1667Z',
      },
      {
        d: 'M41.9307 23.1263C40.2046 22.163 38.2591 21.2963 36.1666 21.1396C28.3713 20.553 21.5561 26.483 20.9769 34.3563C20.6023 39.443 22.929 44.1063 26.7195 46.9346C27.1336 46.193 27.6914 45.3813 28.4356 44.583C25.4191 42.3313 23.566 38.6213 23.8647 34.573C24.3267 28.308 29.7492 23.5896 35.9505 24.0563C37.1666 24.148 36.8366 23.833 37.9059 24.278L38.3201 24.4363C38.3201 24.4363 39.6204 23.088 41.9307 23.1263Z',
      },
      {
        d: 'M91.6764 33.1752C91.6764 26.2385 86.1434 20.5952 79.3415 20.5952C78.4636 20.5952 77.5873 20.6902 76.7309 20.8785C73.9949 14.4152 67.6071 10.1035 60.6946 10.1035C52.0708 10.1035 44.8976 16.2985 43.4702 24.9002C43.4504 24.9002 43.4289 24.9002 43.4091 24.9002C37.7705 24.9002 33.1814 29.5785 33.1814 35.3285C33.1814 37.0452 33.6022 38.6568 34.3266 40.0835C34.9058 39.7935 35.7177 39.5035 36.7639 39.3368C36.0758 38.1602 35.6632 36.7969 35.6632 35.3285C35.6632 30.9685 39.1385 27.4202 43.4091 27.4202C43.6946 27.4202 44.0065 27.4435 44.3893 27.4918L45.65 27.6502L45.7771 26.3668C46.556 18.5335 52.9685 12.6252 60.6946 12.6252C67.0147 12.6252 72.688 16.7168 74.8118 22.8068L75.2127 23.9568L76.3563 23.5868C77.3266 23.2735 78.3299 23.1152 79.3415 23.1152C84.7755 23.1152 89.1962 27.6285 89.1962 33.1752C89.1962 33.7402 89.1418 34.2918 89.0527 34.8318C89.7342 34.9935 90.5873 35.2752 91.4042 35.7502C91.5758 34.9185 91.6764 34.0585 91.6764 33.1752Z',
      },
    ],
  },
  24: {
    width: 66,
    height: 75,
    paths: [
      {
        d: 'M60.765 45.4516L60.3238 42.3079L46.9015 44.2675L36.098 37.909L46.8998 31.5523L60.3238 33.5118L60.765 30.3698L51.2685 28.9812L65.9677 20.3304L64.4111 17.5803L49.7101 26.2328L53.2813 17.1529L50.3894 15.9721L45.3431 28.8021L34.7911 35.0119V22.4454L43.1671 11.5749L40.7181 9.61198L34.7911 17.3049V0H31.6761V17.3049L25.7474 9.61198L23.3001 11.5749L31.6761 22.4454V35.3059L20.6246 28.8021L15.5767 15.9721L12.6848 17.1546L16.2559 26.2328L1.55667 17.5803L0 20.3304L14.6993 28.9812L5.2011 30.3698L5.64396 33.5118L19.0663 31.5523L29.8697 37.909L19.0646 44.2675L5.64396 42.3079L5.2011 45.4516L14.6993 46.8385L0 55.4893L1.55667 58.2395L16.2576 49.587L12.6848 58.6668L15.5767 59.8476L20.6229 47.0176L31.6761 40.5139V53.3237L23.3001 64.1975L25.7491 66.1571L31.6761 58.4658V74.7572H34.7911V58.4658L40.7181 66.1571L43.1671 64.1975L34.7911 53.3254V40.8078L45.3431 47.0176L50.3894 59.8476L53.2813 58.6668L49.7101 49.587L64.4111 58.2395L65.9677 55.4893L51.2685 46.8385L60.765 45.4516Z',
      },
    ],
  },
  25: {
    width: 77,
    height: 76,
    paths: [
      {
        d: 'M60.053 14.7374C58.9246 14.7374 57.7976 14.8599 56.6958 15.1019C53.1734 6.82497 44.9554 1.30371 36.0626 1.30371C24.9648 1.30371 15.7374 9.23586 13.9006 20.253C13.874 20.2516 13.8488 20.2516 13.8222 20.2516C6.56597 20.2516 0.663574 26.243 0.663574 33.6065C0.663574 40.9701 6.56597 46.96 13.8222 46.96H60.053C68.8044 46.96 75.9248 39.7316 75.9248 30.848C75.9248 21.9659 68.8044 14.7374 60.053 14.7374ZM72.7328 30.848C72.7328 37.9526 67.0446 43.7314 60.053 43.7314H13.8222C8.32577 43.7314 3.85557 39.1897 3.85557 33.6065C3.85557 28.0219 8.32577 23.4788 13.8222 23.4788C14.189 23.4788 14.5894 23.5084 15.0836 23.5703L16.7062 23.7744L16.8686 22.1305C17.871 12.0971 26.1212 4.5309 36.0626 4.5309C44.1938 4.5309 51.4934 9.77067 54.2248 17.5705L54.7414 19.0427L56.2114 18.5684C57.4602 18.1687 58.7524 17.966 60.053 17.966C67.0446 17.966 72.7328 23.7448 72.7328 30.848Z',
      },
      {
        d: 'M44.4375 56.1938C44.4375 57.8728 43.0823 59.2352 41.4121 59.2352C39.7419 59.2352 38.3867 57.8728 38.3867 56.1938C38.3867 54.5147 39.7419 53.1523 41.4121 53.1523C43.0823 53.1523 44.4375 54.5147 44.4375 56.1938Z',
      },
      {
        d: 'M50.4869 70.79C50.4869 72.4704 49.1331 73.8314 47.4629 73.8314C45.7913 73.8314 44.4375 72.4704 44.4375 70.79C44.4375 69.111 45.7913 67.75 47.4629 67.75C49.1331 67.75 50.4869 69.111 50.4869 70.79Z',
      },
      {
        d: 'M65.3548 56.1938C65.3548 57.8728 64.001 59.2352 62.3294 59.2352C60.6592 59.2352 59.3054 57.8728 59.3054 56.1938C59.3054 54.5147 60.6592 53.1523 62.3294 53.1523C64.001 53.1523 65.3548 54.5147 65.3548 56.1938Z',
      },
      {
        d: 'M70.1947 70.79C70.1947 72.4704 68.8409 73.8314 67.1693 73.8314C65.4991 73.8314 64.1453 72.4704 64.1453 70.79C64.1453 69.111 65.4991 67.75 67.1693 67.75C68.8409 67.75 70.1947 69.111 70.1947 70.79Z',
      },
      {
        d: 'M25.4605 57.5121C25.4605 59.1911 24.1053 60.5535 22.4351 60.5535C20.7649 60.5535 19.4097 59.1911 19.4097 57.5121C19.4097 55.8331 20.7649 54.4707 22.4351 54.4707C24.1053 54.4707 25.4605 55.8331 25.4605 57.5121Z',
      },
      {
        d: 'M31.5098 72.1084C31.5098 73.7874 30.156 75.1498 28.4844 75.1498C26.8142 75.1498 25.4604 73.7874 25.4604 72.1084C25.4604 70.4293 26.8142 69.0684 28.4844 69.0684C30.156 69.0684 31.5098 70.4293 31.5098 72.1084Z',
      },
    ],
  },
  26: {
    width: 80,
    height: 75,
    paths: [
      {
        d: 'M62.3446 14.6974C61.16 14.6974 59.9785 14.8229 58.8216 15.0739C55.1277 6.48256 46.5077 0.751953 37.18 0.751953C25.5415 0.751953 15.8616 8.98512 13.9354 20.4219C13.9077 20.4203 13.88 20.4203 13.8539 20.4203C6.24309 20.4203 0.0507812 26.6407 0.0507812 34.2846C0.0507812 41.927 6.24309 48.1443 13.8539 48.1443H62.3446C71.5231 48.1443 78.9923 40.6412 78.9923 31.4208C78.9923 22.1989 71.5231 14.6974 62.3446 14.6974ZM75.6431 31.4208C75.6431 38.7953 69.6785 44.7938 62.3446 44.7938H13.8539C8.08924 44.7938 3.39847 40.0795 3.39847 34.2846C3.39847 28.4866 8.08924 23.7708 13.8539 23.7708C14.2385 23.7708 14.6585 23.8014 15.1769 23.8642L16.8769 24.077L17.0492 22.3703C18.0985 11.956 26.7539 4.10246 37.18 4.10246C45.7108 4.10246 53.3662 9.54226 56.2308 17.6377L56.7723 19.1652L58.3154 18.6739C59.6246 18.2576 60.98 18.0479 62.3446 18.0479C69.6785 18.0479 75.6431 24.0463 75.6431 31.4208Z',
      },
      {
        d: 'M14.0878 57.3676C15.497 59.9284 16.9078 62.4891 18.317 65.0498C19.2985 66.8314 22.0401 65.2411 21.057 63.4564C19.6462 60.8957 18.237 58.335 16.8262 55.7743C15.8447 53.9926 13.1047 55.5845 14.0878 57.3676Z',
      },
      {
        d: 'M29.803 57.3676C31.2138 59.9284 32.6246 62.4891 34.0338 65.0498C35.0153 66.8314 37.7569 65.2411 36.7738 63.4564C35.363 60.8957 33.9538 58.335 32.543 55.7743C31.5615 53.9926 28.8215 55.5845 29.803 57.3676Z',
      },
      {
        d: 'M46.323 57.3676C47.7338 59.9284 49.143 62.4891 50.5538 65.0498C51.5353 66.8314 54.2753 65.2411 53.2938 63.4564C51.883 60.8957 50.4723 58.335 49.063 55.7743C48.0815 53.9926 45.34 55.5845 46.323 57.3676Z',
      },
      {
        d: 'M62.2876 57.3676C63.6984 59.9284 65.1076 62.4891 66.5184 65.0498C67.4999 66.8314 70.2399 65.2411 69.2584 63.4564C67.8476 60.8957 66.4369 58.335 65.0276 55.7743C64.0461 53.9926 61.3046 55.5845 62.2876 57.3676Z',
      },
      {
        d: 'M27.003 66.4412C28.4122 69.0019 29.823 71.5626 31.2322 74.1233C32.2137 75.905 34.9553 74.3147 33.9722 72.53C32.563 69.9692 31.1522 67.4085 29.7414 64.8478C28.7614 63.0662 26.0199 64.6565 27.003 66.4412Z',
      },
      {
        d: 'M42.7168 66.436C44.1276 68.9967 45.5368 71.5574 46.9476 74.1181C47.9291 75.9013 50.6691 74.3095 49.6876 72.5248C48.2768 69.9641 46.8661 67.4033 45.4568 64.8426C44.4753 63.061 41.7338 64.6528 42.7168 66.436Z',
      },
      {
        d: 'M58.7477 65.8025C60.1569 68.3632 61.5677 70.9239 62.9769 73.4847C63.9585 75.2663 66.7 73.676 65.7169 71.8913C64.3062 69.3306 62.8969 66.7699 61.4862 64.2091C60.5046 62.4275 57.7646 64.0178 58.7477 65.8025Z',
      },
    ],
  },
  29: {
    width: 80,
    height: 75,
    paths: [
      {
        d: 'M62.3446 14.6974C61.16 14.6974 59.9785 14.8229 58.8216 15.0739C55.1277 6.48256 46.5077 0.751953 37.18 0.751953C25.5415 0.751953 15.8616 8.98512 13.9354 20.4219C13.9077 20.4203 13.88 20.4203 13.8539 20.4203C6.24309 20.4203 0.0507812 26.6407 0.0507812 34.2846C0.0507812 41.927 6.24309 48.1443 13.8539 48.1443H62.3446C71.5231 48.1443 78.9923 40.6412 78.9923 31.4208C78.9923 22.1989 71.5231 14.6974 62.3446 14.6974ZM75.6431 31.4208C75.6431 38.7953 69.6785 44.7938 62.3446 44.7938H13.8539C8.08924 44.7938 3.39847 40.0795 3.39847 34.2846C3.39847 28.4866 8.08924 23.7708 13.8539 23.7708C14.2385 23.7708 14.6585 23.8014 15.1769 23.8642L16.8769 24.077L17.0492 22.3703C18.0985 11.956 26.7539 4.10246 37.18 4.10246C45.7108 4.10246 53.3662 9.54226 56.2308 17.6377L56.7723 19.1652L58.3154 18.6739C59.6246 18.2576 60.98 18.0479 62.3446 18.0479C69.6785 18.0479 75.6431 24.0463 75.6431 31.4208Z',
      },
      {
        d: 'M14.0878 57.3676C15.497 59.9284 16.9078 62.4891 18.317 65.0498C19.2985 66.8314 22.0401 65.2411 21.057 63.4564C19.6462 60.8957 18.237 58.335 16.8262 55.7743C15.8447 53.9926 13.1047 55.5845 14.0878 57.3676Z',
      },
      {
        d: 'M29.803 57.3676C31.2138 59.9284 32.6246 62.4891 34.0338 65.0498C35.0153 66.8314 37.7569 65.2411 36.7738 63.4564C35.363 60.8957 33.9538 58.335 32.543 55.7743C31.5615 53.9926 28.8215 55.5845 29.803 57.3676Z',
      },
      {
        d: 'M46.323 57.3676C47.7338 59.9284 49.143 62.4891 50.5538 65.0498C51.5353 66.8314 54.2753 65.2411 53.2938 63.4564C51.883 60.8957 50.4723 58.335 49.063 55.7743C48.0815 53.9926 45.34 55.5845 46.323 57.3676Z',
      },
      {
        d: 'M62.2876 57.3676C63.6984 59.9284 65.1076 62.4891 66.5184 65.0498C67.4999 66.8314 70.2399 65.2411 69.2584 63.4564C67.8476 60.8957 66.4369 58.335 65.0276 55.7743C64.0461 53.9926 61.3046 55.5845 62.2876 57.3676Z',
      },
      {
        d: 'M27.003 66.4412C28.4122 69.0019 29.823 71.5626 31.2322 74.1233C32.2137 75.905 34.9553 74.3147 33.9722 72.53C32.563 69.9692 31.1522 67.4085 29.7414 64.8478C28.7614 63.0662 26.0199 64.6565 27.003 66.4412Z',
      },
      {
        d: 'M42.7168 66.436C44.1276 68.9967 45.5368 71.5574 46.9476 74.1181C47.9291 75.9013 50.6691 74.3095 49.6876 72.5248C48.2768 69.9641 46.8661 67.4033 45.4568 64.8426C44.4753 63.061 41.7338 64.6528 42.7168 66.436Z',
      },
      {
        d: 'M58.7477 65.8025C60.1569 68.3632 61.5677 70.9239 62.9769 73.4847C63.9585 75.2663 66.7 73.676 65.7169 71.8913C64.3062 69.3306 62.8969 66.7699 61.4862 64.2091C60.5046 62.4275 57.7646 64.0178 58.7477 65.8025Z',
      },
    ],
  },
  33: {
    width: 61,
    height: 64,
    paths: [
      {
        d: 'M30.0319 63.0265C20.2534 64.0306 11.9804 60.9459 5.1222 54.0882C3.48636 52.4523 2.054 50.6153 0.559639 48.8443C-0.100105 48.0602 -0.144618 47.4788 0.259177 46.8675C0.659793 46.2641 1.32907 46.1211 2.2861 46.3568C9.04251 48.0162 15.7353 47.9046 22.0943 44.9488C32.3688 40.1716 38.1936 32.0253 39.6324 20.9373C40.4733 14.4489 38.8089 8.41149 35.4275 2.82035C35.321 2.64592 35.216 2.47149 35.1064 2.29863C34.7248 1.6952 34.7137 1.0902 35.1747 0.53549C35.6389 -0.0207958 36.2621 -0.119796 36.9187 0.12849C46.7194 3.82606 54.3756 9.94363 57.9971 19.8939C62.539 32.3726 60.0208 43.6193 50.6604 53.1893C46.0645 57.8895 40.3859 60.8375 33.9506 62.3319C32.6597 62.6321 31.3387 62.7986 30.0319 63.0265ZM5.11743 49.9255C5.712 50.5729 6.23184 51.1653 6.78031 51.7295C10.5035 55.5685 14.8085 58.5118 20.1453 59.6071C29.2688 61.4786 37.6849 59.6353 45.2171 54.2831C56.0019 46.6208 59.5836 33.9063 55.7412 21.9493C53.1293 13.8251 47.5064 8.27006 39.9153 4.5112C39.8502 4.47978 39.7675 4.48135 39.6991 4.46878C44.489 16.0926 43.6592 27.2042 36.0825 37.2943C28.458 47.4489 17.8433 51.2502 5.11743 49.9255Z',
      },
    ],
  },
  34: {
    width: 99,
    height: 68,
    paths: [
      {
        d: 'M30.1147 63.0904C27.5025 63.2021 24.8301 63.0127 22.085 62.4282C16.7782 61.2997 12.4946 58.2688 8.79278 54.3135C8.24724 53.7307 7.72858 53.1219 7.13719 52.4548C19.7953 53.8197 30.3551 49.9016 37.9373 39.4409C45.4753 29.045 46.2991 17.5968 41.5347 5.62065C41.6027 5.63361 41.6849 5.63199 41.7498 5.66437C49.0995 9.43351 54.5708 14.9755 57.2542 22.9866C58.1176 22.7291 59.0569 22.5025 60.0072 22.3665C59.9139 22.0815 59.8348 21.7982 59.7352 21.5132C56.1331 11.2598 48.5192 4.95846 38.7706 1.14723C38.1176 0.893036 37.4961 0.993416 37.036 1.56818C36.5758 2.13808 36.5885 2.76304 36.9664 3.38475C37.0755 3.56285 37.1815 3.74256 37.2858 3.92227C40.6492 9.68123 42.3048 15.9032 41.4683 22.5867C40.0372 34.0123 34.2434 42.4054 24.0236 47.3257C17.6985 50.3711 11.0414 50.4877 4.32093 48.778C3.369 48.5351 2.70328 48.6825 2.3048 49.3042C1.90316 49.9324 1.94743 50.533 2.60524 51.3393C4.09006 53.1656 5.51479 55.0583 7.14193 56.7437C13.9636 63.8092 22.1941 66.9874 31.919 65.9512C32.0803 65.9221 32.2431 65.8978 32.406 65.8703C31.5932 65.1029 30.7978 64.1929 30.1147 63.0904Z',
      },
      {
        d: 'M84.1548 38.8245C83.1665 38.8245 82.1813 38.9329 81.2183 39.1483C78.1396 31.7768 70.9543 26.8613 63.1791 26.8613C53.4764 26.8613 45.4087 33.9252 43.8021 43.735C43.78 43.735 43.7563 43.735 43.7341 43.735C37.3901 43.735 32.2288 49.0698 32.2288 55.6269C32.2288 62.1841 37.3901 67.5172 43.7341 67.5172H84.1548C91.8066 67.5172 98.0305 61.0815 98.0305 53.1709C98.0305 45.2602 91.8066 38.8245 84.1548 38.8245ZM95.2395 53.1709C95.2395 59.4965 90.268 64.6434 84.1548 64.6434H43.7341C38.9286 64.6434 35.0197 60.599 35.0197 55.6269C35.0197 50.6532 38.9286 46.6089 43.7341 46.6089C44.0551 46.6089 44.4046 46.6348 44.8363 46.6882L46.2547 46.8711L46.397 45.4075C47.273 36.472 54.4868 29.7351 63.1791 29.7351C70.2885 29.7351 76.6706 34.4012 79.0583 41.3469L79.5105 42.6568L80.7961 42.2358C81.8872 41.878 83.0178 41.6983 84.1548 41.6983C90.268 41.6983 95.2395 46.8436 95.2395 53.1709Z',
      },
    ],
  },
  35: {
    width: 99,
    height: 68,
    paths: [
      {
        d: 'M30.115 63.0904C27.5027 63.2021 24.8303 63.0127 22.0852 62.4282C16.7785 61.2997 12.4948 58.2688 8.79303 54.3135C8.24749 53.7307 7.72883 53.1219 7.13743 52.4548C19.7956 53.8197 30.3553 49.9016 37.9376 39.4409C45.4755 29.045 46.2994 17.5968 41.535 5.62065C41.603 5.63361 41.6852 5.63199 41.75 5.66437C49.0998 9.43351 54.571 14.9755 57.2544 22.9866C58.1178 22.7291 59.0571 22.5025 60.0075 22.3665C59.9142 22.0815 59.8351 21.7982 59.7355 21.5132C56.1333 11.2598 48.5195 4.95846 38.7709 1.14723C38.1178 0.893036 37.4964 0.993416 37.0362 1.56818C36.5761 2.13808 36.5887 2.76304 36.9667 3.38475C37.0758 3.56285 37.1817 3.74256 37.2861 3.92227C40.6494 9.68123 42.305 15.9032 41.4685 22.5867C40.0375 34.0123 34.2437 42.4054 24.0239 47.3257C17.6988 50.3711 11.0416 50.4877 4.32117 48.778C3.36925 48.5351 2.70353 48.6825 2.30505 49.3042C1.9034 49.9324 1.94768 50.533 2.60549 51.3393C4.09031 53.1656 5.51504 55.0583 7.14217 56.7437C13.9638 63.8092 22.1944 66.9874 31.9192 65.9512C32.0805 65.9221 32.2434 65.8978 32.4062 65.8703C31.5935 65.1029 30.7981 64.1929 30.115 63.0904Z',
      },
      {
        d: 'M84.155 38.8245C83.1667 38.8245 82.1816 38.9329 81.2186 39.1483C78.1398 31.7768 70.9545 26.8613 63.1794 26.8613C53.4766 26.8613 45.409 33.9252 43.8024 43.735C43.7802 43.735 43.7565 43.735 43.7344 43.735C37.3903 43.735 32.229 49.0698 32.229 55.6269C32.229 62.1841 37.3903 67.5172 43.7344 67.5172H84.155C91.8068 67.5172 98.0307 61.0815 98.0307 53.1709C98.0307 45.2602 91.8068 38.8245 84.155 38.8245ZM95.2398 53.1709C95.2398 59.4965 90.2682 64.6434 84.155 64.6434H43.7344C38.9289 64.6434 35.02 60.599 35.02 55.6269C35.02 50.6532 38.9289 46.6089 43.7344 46.6089C44.0554 46.6089 44.4048 46.6348 44.8365 46.6882L46.2549 46.8711L46.3973 45.4075C47.2733 36.472 54.4871 29.7351 63.1794 29.7351C70.2888 29.7351 76.6708 34.4012 79.0585 41.3469L79.5108 42.6568L80.7964 42.2358C81.8875 41.878 83.0181 41.6983 84.155 41.6983C90.2682 41.6983 95.2398 46.8436 95.2398 53.1709Z',
      },
    ],
  },
  36: {
    width: 99,
    height: 68,
    paths: [
      {
        d: 'M30.115 63.0904C27.5027 63.2021 24.8303 63.0127 22.0852 62.4282C16.7785 61.2997 12.4948 58.2688 8.79303 54.3135C8.24749 53.7307 7.72883 53.1219 7.13743 52.4548C19.7956 53.8197 30.3553 49.9016 37.9376 39.4409C45.4755 29.045 46.2994 17.5968 41.535 5.62065C41.603 5.63361 41.6852 5.63199 41.75 5.66437C49.0998 9.43351 54.571 14.9755 57.2544 22.9866C58.1178 22.7291 59.0571 22.5025 60.0075 22.3665C59.9142 22.0815 59.8351 21.7982 59.7355 21.5132C56.1333 11.2598 48.5195 4.95846 38.7709 1.14723C38.1178 0.893036 37.4964 0.993416 37.0362 1.56818C36.5761 2.13808 36.5887 2.76304 36.9667 3.38475C37.0758 3.56285 37.1817 3.74256 37.2861 3.92227C40.6494 9.68123 42.305 15.9032 41.4685 22.5867C40.0375 34.0123 34.2437 42.4054 24.0239 47.3257C17.6988 50.3711 11.0416 50.4877 4.32117 48.778C3.36925 48.5351 2.70353 48.6825 2.30505 49.3042C1.9034 49.9324 1.94768 50.533 2.60549 51.3393C4.09031 53.1656 5.51504 55.0583 7.14217 56.7437C13.9638 63.8092 22.1944 66.9874 31.9192 65.9512C32.0805 65.9221 32.2434 65.8978 32.4062 65.8703C31.5935 65.1029 30.7981 64.1929 30.115 63.0904Z',
      },
      {
        d: 'M84.155 38.8245C83.1667 38.8245 82.1816 38.9329 81.2186 39.1483C78.1398 31.7768 70.9545 26.8613 63.1794 26.8613C53.4766 26.8613 45.409 33.9252 43.8024 43.735C43.7802 43.735 43.7565 43.735 43.7344 43.735C37.3903 43.735 32.229 49.0698 32.229 55.6269C32.229 62.1841 37.3903 67.5172 43.7344 67.5172H84.155C91.8068 67.5172 98.0307 61.0815 98.0307 53.1709C98.0307 45.2602 91.8068 38.8245 84.155 38.8245ZM95.2398 53.1709C95.2398 59.4965 90.2682 64.6434 84.155 64.6434H43.7344C38.9289 64.6434 35.02 60.599 35.02 55.6269C35.02 50.6532 38.9289 46.6089 43.7344 46.6089C44.0554 46.6089 44.4048 46.6348 44.8365 46.6882L46.2549 46.8711L46.3973 45.4075C47.2733 36.472 54.4871 29.7351 63.1794 29.7351C70.2888 29.7351 76.6708 34.4012 79.0585 41.3469L79.5108 42.6568L80.7964 42.2358C81.8875 41.878 83.0181 41.6983 84.155 41.6983C90.2682 41.6983 95.2398 46.8436 95.2398 53.1709Z',
      },
    ],
  },
  37: {
    id: 37,
    width: 79,
    height: 80,
    paths: [
      {
        d: 'M4.59197 56.807H76.3505C77.3829 56.807 78.2276 56.0145 78.2276 55.0443C78.2276 54.0757 77.3829 53.2832 76.3505 53.2832H4.59197C3.55955 53.2832 2.71484 54.0757 2.71484 55.0443C2.71484 56.0145 3.55955 56.807 4.59197 56.807Z',
      },
      {
        d: 'M21.3054 68.1918H76.3505C77.3829 68.1918 78.2276 67.3993 78.2276 66.4291C78.2276 65.4605 77.3829 64.668 76.3505 64.668H21.3054C20.273 64.668 19.4265 65.4605 19.4265 66.4291C19.4265 67.3993 20.273 68.1918 21.3054 68.1918Z',
      },
      {
        d: 'M61.7489 13.68C60.5757 13.68 59.406 13.8017 58.2623 14.04C54.6054 5.84333 46.0714 0.375 36.8387 0.375C25.317 0.375 15.7349 8.23167 13.8283 19.1417C13.8004 19.1417 13.7744 19.1417 13.7466 19.1417C6.21371 19.1417 0.0834961 25.075 0.0834961 32.3683C0.0834961 39.6617 6.21371 45.5933 13.7466 45.5933H61.7489C70.8356 45.5933 78.2277 38.435 78.2277 29.6367C78.2277 20.8383 70.8356 13.68 61.7489 13.68ZM74.9132 29.6367C74.9132 36.6733 69.0089 42.3967 61.7489 42.3967H13.7466C8.04044 42.3967 3.39802 37.8983 3.39802 32.3683C3.39802 26.8383 8.04044 22.3383 13.7466 22.3383C14.1272 22.3383 14.5443 22.3667 15.0571 22.4283L16.7413 22.63L16.9099 21.0017C17.9492 11.065 26.518 3.57167 36.8387 3.57167C45.2823 3.57167 52.8604 8.76167 55.6969 16.4867L56.234 17.945L57.76 17.475C59.0566 17.0783 60.3984 16.8767 61.7489 16.8767C69.0089 16.8767 74.9132 22.6 74.9132 29.6367Z',
      },
      {
        d: 'M39.4528 79.1195H76.3506C77.383 79.1195 78.2277 78.3267 78.2277 77.3576C78.2277 76.3886 77.383 75.5957 76.3506 75.5957H39.4528C38.4187 75.5957 37.574 76.3886 37.574 77.3576C37.574 78.3267 38.4187 79.1195 39.4528 79.1195Z',
      },
    ],
  },
  38: {
    width: 100,
    height: 69,
    paths: [
      {
        d: 'M31.7409 40.9278H9.32578C5.5682 40.9278 2.51214 37.5797 2.51214 33.4627C2.51214 29.344 5.5682 25.9942 9.32578 25.9942C9.57729 25.9942 9.85153 26.0156 10.1879 26.0616L11.297 26.2127L11.4091 25.0003C12.094 17.6025 17.7349 12.0234 24.5303 12.0234C30.0894 12.0234 35.0788 15.8874 36.9455 21.6374L37.2985 22.7233L38.3046 22.3734C39.1576 22.0793 40.0409 21.9298 40.9303 21.9298C41.1061 21.9298 41.2773 21.9462 41.4515 21.9577C41.5364 21.1905 41.6061 20.4052 41.644 19.5887C41.4076 19.5723 41.1712 19.5493 40.9303 19.5493C40.1591 19.5493 39.3879 19.6397 38.6349 19.8171C36.2273 13.7155 30.6091 9.64453 24.5303 9.64453C16.944 9.64453 10.6349 15.4931 9.37881 23.6154C9.36214 23.6154 9.34396 23.6154 9.32578 23.6154C4.36517 23.6154 0.330322 28.033 0.330322 33.4627C0.330322 38.8907 4.36517 43.3083 9.32578 43.3083H28.2667L28.2379 43.3017C28.2379 43.3017 29.7424 42.5871 31.7409 40.9278Z',
      },
      {
        d: 'M34.0091 63.0724C31.5061 63.1857 28.9455 62.9919 26.3167 62.4005C21.2303 61.2554 17.1258 58.18 13.5788 54.1665C13.0561 53.575 12.5606 52.9557 11.994 52.2805C24.1227 53.6637 34.2394 49.6897 41.5061 39.0752C48.7273 28.5264 49.5167 16.9097 44.953 4.75753C45.0167 4.77067 45.0955 4.76739 45.1576 4.80189C52.2015 8.62481 57.4424 14.25 60.0137 22.3788C60.8409 22.116 61.7409 21.8876 62.6515 21.748C62.5621 21.4588 62.4879 21.173 62.3909 20.8822C58.9394 10.4796 51.644 4.08396 42.3031 0.218315C41.6773 -0.0412566 41.0818 0.0622434 40.6409 0.643815C40.2015 1.22374 40.2121 1.85624 40.5743 2.48874C40.6788 2.66781 40.7803 2.85017 40.8803 3.03417C44.1031 8.87782 45.6894 15.1913 44.8879 21.973C43.5167 33.5667 37.9652 42.0832 28.1727 47.0759C22.1121 50.1661 15.7334 50.2827 9.29396 48.5479C8.38184 48.3031 7.74396 48.451 7.36214 49.0835C6.97729 49.7209 7.01972 50.3287 7.65002 51.1485C9.07275 53 10.4379 54.9205 11.997 56.6324C18.5334 63.8018 26.4197 67.0251 35.7379 65.9753C35.8924 65.9457 36.0485 65.9195 36.2046 65.8915C35.4258 65.1145 34.6637 64.1895 34.0091 63.0724Z',
      },
      {
        d: 'M85.7895 38.4496C84.844 38.4496 83.8985 38.5597 82.9758 38.7782C80.0258 31.2999 73.141 26.3105 65.691 26.3105C56.394 26.3105 48.6637 33.4783 47.1243 43.434C47.1031 43.4324 47.0804 43.4324 47.0592 43.4324C40.9804 43.4324 36.0349 48.8473 36.0349 55.5008C36.0349 62.1528 40.9804 67.566 47.0592 67.566H85.7895C93.1213 67.566 99.0864 61.034 99.0864 53.007C99.0864 44.9816 93.1213 38.4496 85.7895 38.4496ZM96.4122 53.007C96.4122 59.4273 91.647 64.6483 85.7895 64.6483H47.0592C42.4546 64.6483 38.7092 60.5444 38.7092 55.5008C38.7092 50.454 42.4546 46.3485 47.0592 46.3485C47.3667 46.3485 47.7016 46.3748 48.1152 46.4306L49.4743 46.6146L49.6107 45.1295C50.4501 36.0642 57.3637 29.2283 65.691 29.2283C72.5031 29.2283 78.6182 33.963 80.9061 41.0108L81.3395 42.3399L82.5713 41.9111C83.6167 41.5497 84.7001 41.3673 85.7895 41.3673C91.647 41.3673 96.4122 46.5883 96.4122 53.007Z',
      },
    ],
  },
  39: {
    width: 99,
    height: 68,
    paths: [
      {
        d: 'M30.115 63.0904C27.5027 63.2021 24.8303 63.0127 22.0852 62.4282C16.7785 61.2997 12.4948 58.2688 8.79303 54.3135C8.24749 53.7307 7.72883 53.1219 7.13743 52.4548C19.7956 53.8197 30.3553 49.9016 37.9376 39.4409C45.4755 29.045 46.2994 17.5968 41.535 5.62065C41.603 5.63361 41.6852 5.63199 41.75 5.66437C49.0998 9.43351 54.571 14.9755 57.2544 22.9866C58.1178 22.7291 59.0571 22.5025 60.0075 22.3665C59.9142 22.0815 59.8351 21.7982 59.7355 21.5132C56.1333 11.2598 48.5195 4.95846 38.7709 1.14723C38.1178 0.893036 37.4964 0.993416 37.0362 1.56818C36.5761 2.13808 36.5887 2.76304 36.9667 3.38475C37.0758 3.56285 37.1817 3.74256 37.2861 3.92227C40.6494 9.68123 42.305 15.9032 41.4685 22.5867C40.0375 34.0123 34.2437 42.4054 24.0239 47.3257C17.6988 50.3711 11.0416 50.4877 4.32117 48.778C3.36925 48.5351 2.70353 48.6825 2.30505 49.3042C1.9034 49.9324 1.94768 50.533 2.60549 51.3393C4.09031 53.1656 5.51504 55.0583 7.14217 56.7437C13.9638 63.8092 22.1944 66.9874 31.9192 65.9512C32.0805 65.9221 32.2434 65.8978 32.4062 65.8703C31.5935 65.1029 30.7981 64.1929 30.115 63.0904Z',
      },
      {
        d: 'M84.155 38.8245C83.1667 38.8245 82.1816 38.9329 81.2186 39.1483C78.1398 31.7768 70.9545 26.8613 63.1794 26.8613C53.4766 26.8613 45.409 33.9252 43.8024 43.735C43.7802 43.735 43.7565 43.735 43.7344 43.735C37.3903 43.735 32.229 49.0698 32.229 55.6269C32.229 62.1841 37.3903 67.5172 43.7344 67.5172H84.155C91.8068 67.5172 98.0307 61.0815 98.0307 53.1709C98.0307 45.2602 91.8068 38.8245 84.155 38.8245ZM95.2398 53.1709C95.2398 59.4965 90.2682 64.6434 84.155 64.6434H43.7344C38.9289 64.6434 35.02 60.599 35.02 55.6269C35.02 50.6532 38.9289 46.6089 43.7344 46.6089C44.0554 46.6089 44.4048 46.6348 44.8365 46.6882L46.2549 46.8711L46.3973 45.4075C47.2733 36.472 54.4871 29.7351 63.1794 29.7351C70.2888 29.7351 76.6708 34.4012 79.0585 41.3469L79.5108 42.6568L80.7964 42.2358C81.8875 41.878 83.0181 41.6983 84.155 41.6983C90.2682 41.6983 95.2398 46.8436 95.2398 53.1709Z',
      },
    ],
  },
  40: {
    width: 100,
    height: 69,
    paths: [
      {
        d: 'M31.7409 40.9278H9.32578C5.5682 40.9278 2.51214 37.5797 2.51214 33.4627C2.51214 29.344 5.5682 25.9942 9.32578 25.9942C9.57729 25.9942 9.85153 26.0156 10.1879 26.0616L11.297 26.2127L11.4091 25.0003C12.094 17.6025 17.7349 12.0234 24.5303 12.0234C30.0894 12.0234 35.0788 15.8874 36.9455 21.6374L37.2985 22.7233L38.3046 22.3734C39.1576 22.0793 40.0409 21.9298 40.9303 21.9298C41.1061 21.9298 41.2773 21.9462 41.4515 21.9577C41.5364 21.1905 41.6061 20.4052 41.644 19.5887C41.4076 19.5723 41.1712 19.5493 40.9303 19.5493C40.1591 19.5493 39.3879 19.6397 38.6349 19.8171C36.2273 13.7155 30.6091 9.64453 24.5303 9.64453C16.944 9.64453 10.6349 15.4931 9.37881 23.6154C9.36214 23.6154 9.34396 23.6154 9.32578 23.6154C4.36517 23.6154 0.330322 28.033 0.330322 33.4627C0.330322 38.8907 4.36517 43.3083 9.32578 43.3083H28.2667L28.2379 43.3017C28.2379 43.3017 29.7424 42.5871 31.7409 40.9278Z',
      },
      {
        d: 'M34.0091 63.0724C31.5061 63.1857 28.9455 62.9919 26.3167 62.4005C21.2303 61.2554 17.1258 58.18 13.5788 54.1665C13.0561 53.575 12.5606 52.9557 11.994 52.2805C24.1227 53.6637 34.2394 49.6897 41.5061 39.0752C48.7273 28.5264 49.5167 16.9097 44.953 4.75753C45.0167 4.77067 45.0955 4.76739 45.1576 4.80189C52.2015 8.62481 57.4424 14.25 60.0137 22.3788C60.8409 22.116 61.7409 21.8876 62.6515 21.748C62.5621 21.4588 62.4879 21.173 62.3909 20.8822C58.9394 10.4796 51.644 4.08396 42.3031 0.218315C41.6773 -0.0412566 41.0818 0.0622434 40.6409 0.643815C40.2015 1.22374 40.2121 1.85624 40.5743 2.48874C40.6788 2.66781 40.7803 2.85017 40.8803 3.03417C44.1031 8.87782 45.6894 15.1913 44.8879 21.973C43.5167 33.5667 37.9652 42.0832 28.1727 47.0759C22.1121 50.1661 15.7334 50.2827 9.29396 48.5479C8.38184 48.3031 7.74396 48.451 7.36214 49.0835C6.97729 49.7209 7.01972 50.3287 7.65002 51.1485C9.07275 53 10.4379 54.9205 11.997 56.6324C18.5334 63.8018 26.4197 67.0251 35.7379 65.9753C35.8924 65.9457 36.0485 65.9195 36.2046 65.8915C35.4258 65.1145 34.6637 64.1895 34.0091 63.0724Z',
      },
      {
        d: 'M85.7895 38.4496C84.844 38.4496 83.8985 38.5597 82.9758 38.7782C80.0258 31.2999 73.141 26.3105 65.691 26.3105C56.394 26.3105 48.6637 33.4783 47.1243 43.434C47.1031 43.4324 47.0804 43.4324 47.0592 43.4324C40.9804 43.4324 36.0349 48.8473 36.0349 55.5008C36.0349 62.1528 40.9804 67.566 47.0592 67.566H85.7895C93.1213 67.566 99.0864 61.034 99.0864 53.007C99.0864 44.9816 93.1213 38.4496 85.7895 38.4496ZM96.4122 53.007C96.4122 59.4273 91.647 64.6483 85.7895 64.6483H47.0592C42.4546 64.6483 38.7092 60.5444 38.7092 55.5008C38.7092 50.454 42.4546 46.3485 47.0592 46.3485C47.3667 46.3485 47.7016 46.3748 48.1152 46.4306L49.4743 46.6146L49.6107 45.1295C50.4501 36.0642 57.3637 29.2283 65.691 29.2283C72.5031 29.2283 78.6182 33.963 80.9061 41.0108L81.3395 42.3399L82.5713 41.9111C83.6167 41.5497 84.7001 41.3673 85.7895 41.3673C91.647 41.3673 96.4122 46.5883 96.4122 53.007Z',
      },
    ],
  },
  41: {
    width: 94,
    height: 83,
    paths: [
      {
        d: 'M17.7887 33.0443H11.3363C7.13049 33.0443 3.70755 29.5406 3.70755 25.2341C3.70755 20.9263 7.13049 17.4226 11.3363 17.4226C11.617 17.4226 11.9231 17.4443 12.3018 17.492L13.5426 17.6498L13.6675 16.3817C14.4343 8.64226 20.7484 2.80641 28.357 2.80641C31.6605 2.80641 34.7786 3.9548 37.2951 5.92367C37.839 5.29777 38.4083 4.70452 39.0099 4.15346C36.0207 1.73557 32.2768 0.316406 28.357 0.316406C19.8635 0.316406 12.8014 6.43528 11.3954 14.9326C11.3753 14.9326 11.3565 14.9326 11.3363 14.9326C5.78361 14.9326 1.26489 19.5547 1.26489 25.2341C1.26489 30.9135 5.78361 35.5343 11.3363 35.5343H17.8451C17.7874 35.0186 17.7485 34.4974 17.7485 33.9641C17.7485 33.6538 17.7686 33.349 17.7887 33.0443Z',
      },
      {
        d: 'M77.9932 14.9582C76.9108 14.9582 75.8298 15.0765 74.773 15.3106C71.3944 7.30856 63.5118 1.9707 54.982 1.9707C44.3385 1.9707 35.4877 9.63936 33.7245 20.2892C33.7004 20.2892 33.6749 20.2892 33.6507 20.2892C26.6907 20.2892 21.0278 26.0815 21.0278 33.2005C21.0278 40.3194 26.6907 46.109 33.6507 46.109H77.9932C86.3874 46.109 93.2171 39.122 93.2171 30.5336C93.2171 21.9451 86.3874 14.9582 77.9932 14.9582ZM90.1554 30.5336C90.1554 37.4022 84.7007 42.989 77.9932 42.989H33.6507C28.3786 42.989 24.0895 38.5982 24.0895 33.2005C24.0895 27.8014 28.3786 23.4092 33.6507 23.4092C34.0025 23.4092 34.3866 23.4378 34.8593 23.4963L36.4156 23.6949L36.5714 22.1057C37.5329 12.4056 45.4477 5.09069 54.982 5.09069C62.7826 5.09069 69.7829 10.1564 72.4029 17.6972L72.8984 19.1204L74.3097 18.6619C75.5075 18.2754 76.747 18.0795 77.9932 18.0795C84.7007 18.0795 90.1554 23.6664 90.1554 30.5336Z',
      },
      {
        d: 'M33.8643 54.7001C35.1534 57.0839 36.4426 59.4692 37.7331 61.8544C38.6301 63.5144 41.1359 62.0327 40.2375 60.3699C38.9483 57.9861 37.6592 55.6008 36.3687 53.2156C35.4717 51.557 32.9659 53.0387 33.8643 54.7001Z',
      },
      {
        d: 'M77.9422 54.7001C79.2313 57.0839 80.5218 59.4692 81.8109 61.8544C82.708 63.5144 85.2151 62.0327 84.3167 60.3699C83.0262 57.9861 81.7371 55.6008 80.4479 53.2156C79.5496 51.557 77.0438 53.0387 77.9422 54.7001Z',
      },
      {
        d: 'M74.7044 62.5551C75.9935 64.9403 77.2827 67.3256 78.5731 69.7108C79.4702 71.3694 81.9759 69.8877 81.0776 68.2263C79.7884 65.8411 78.4993 63.4572 77.2088 61.072C76.3118 59.412 73.806 60.8937 74.7044 62.5551Z',
      },
      {
        d: 'M11.9353 45.1821C13.2257 47.5673 14.5149 49.9525 15.804 52.3377C16.7011 53.9964 19.2082 52.5146 18.3098 50.8533C17.0207 48.468 15.7302 46.0842 14.441 43.6989C13.544 42.0389 11.0369 43.5207 11.9353 45.1821Z',
      },
      {
        d: 'M23.7471 53.6323C25.0362 56.0176 26.3254 58.4014 27.6159 60.7866C28.5129 62.4466 31.0187 60.9649 30.1203 59.3035C28.8312 56.9183 27.542 54.5331 26.2515 52.1478C25.3545 50.4878 22.8487 51.971 23.7471 53.6323Z',
      },
      {
        d: 'M20.3684 63.1488C21.6576 65.5341 22.9467 67.9193 24.2372 70.3045C25.1342 71.9632 27.6413 70.4814 26.7416 68.8201C25.4525 66.4348 24.1633 64.0496 22.8742 61.6657C21.9758 60.0057 19.4701 61.4875 20.3684 63.1488Z',
      },
      {
        d: 'M33.8643 64.7905C35.1534 67.1744 36.4426 69.5596 37.7331 71.9449C38.6301 73.6049 41.1359 72.1231 40.2375 70.4604C38.9483 68.0765 37.6592 65.6913 36.3687 63.306C35.4717 61.646 32.9659 63.1292 33.8643 64.7905Z',
      },
      {
        d: 'M61.856 45.9629L61.5471 46.1085H61.9245L61.856 45.9629Z',
      },
      {
        d: 'M47.8582 45.8301L47.4258 46.1077H48.0328L47.8582 45.8301Z',
      },
      {
        d: 'M67.9728 64.696H63.2996C62.6806 64.696 62.1058 65.0076 61.7621 65.5315C61.4196 66.054 61.3552 66.7125 61.5942 67.2922L65.9813 77.9243L48.1173 58.7296H52.589C53.2631 58.7296 53.8849 58.3567 54.2085 57.758C54.5335 57.158 54.5106 56.4287 54.1481 55.8504L49.7314 48.8145H46.9799L51.7269 56.377H47.0229C46.2843 56.377 45.6169 56.8219 45.3255 57.5077C45.0328 58.1948 45.1711 58.9908 45.6773 59.5351L66.0726 81.4498C66.4379 81.843 66.92 82.0457 67.4115 82.0457C67.7579 82.0457 68.1084 81.9451 68.424 81.7369C69.1854 81.2362 69.4741 80.2932 69.1236 79.4428L64.01 67.0486H68.7167C69.3532 67.0486 69.9374 66.722 70.2785 66.1778C70.6182 65.6335 70.6612 64.96 70.3899 64.379L63.1774 48.8145H60.6139L67.9728 64.696Z',
      },
    ],
  },
  42: {
    width: 94,
    height: 83,
    paths: [
      {
        d: 'M17.7887 33.0443H11.3363C7.13049 33.0443 3.70755 29.5406 3.70755 25.2341C3.70755 20.9263 7.13049 17.4226 11.3363 17.4226C11.617 17.4226 11.9231 17.4443 12.3018 17.492L13.5426 17.6498L13.6675 16.3817C14.4343 8.64226 20.7484 2.80641 28.357 2.80641C31.6605 2.80641 34.7786 3.9548 37.2951 5.92367C37.839 5.29777 38.4083 4.70452 39.0099 4.15346C36.0207 1.73557 32.2768 0.316406 28.357 0.316406C19.8635 0.316406 12.8014 6.43528 11.3954 14.9326C11.3753 14.9326 11.3565 14.9326 11.3363 14.9326C5.78361 14.9326 1.26489 19.5547 1.26489 25.2341C1.26489 30.9135 5.78361 35.5343 11.3363 35.5343H17.8451C17.7874 35.0186 17.7485 34.4974 17.7485 33.9641C17.7485 33.6538 17.7686 33.349 17.7887 33.0443Z',
      },
      {
        d: 'M77.9932 14.9582C76.9108 14.9582 75.8298 15.0765 74.773 15.3106C71.3944 7.30856 63.5118 1.9707 54.982 1.9707C44.3385 1.9707 35.4877 9.63936 33.7245 20.2892C33.7004 20.2892 33.6749 20.2892 33.6507 20.2892C26.6907 20.2892 21.0278 26.0815 21.0278 33.2005C21.0278 40.3194 26.6907 46.109 33.6507 46.109H77.9932C86.3874 46.109 93.2171 39.122 93.2171 30.5336C93.2171 21.9451 86.3874 14.9582 77.9932 14.9582ZM90.1554 30.5336C90.1554 37.4022 84.7007 42.989 77.9932 42.989H33.6507C28.3786 42.989 24.0895 38.5982 24.0895 33.2005C24.0895 27.8014 28.3786 23.4092 33.6507 23.4092C34.0025 23.4092 34.3866 23.4378 34.8593 23.4963L36.4156 23.6949L36.5714 22.1057C37.5329 12.4056 45.4477 5.09069 54.982 5.09069C62.7826 5.09069 69.7829 10.1564 72.4029 17.6972L72.8984 19.1204L74.3097 18.6619C75.5075 18.2754 76.747 18.0795 77.9932 18.0795C84.7007 18.0795 90.1554 23.6664 90.1554 30.5336Z',
      },
      {
        d: 'M33.8643 54.7001C35.1534 57.0839 36.4426 59.4692 37.7331 61.8544C38.6301 63.5144 41.1359 62.0327 40.2375 60.3699C38.9483 57.9861 37.6592 55.6008 36.3687 53.2156C35.4717 51.557 32.9659 53.0387 33.8643 54.7001Z',
      },
      {
        d: 'M77.9422 54.7001C79.2313 57.0839 80.5218 59.4692 81.8109 61.8544C82.708 63.5144 85.2151 62.0327 84.3167 60.3699C83.0262 57.9861 81.7371 55.6008 80.4479 53.2156C79.5496 51.557 77.0438 53.0387 77.9422 54.7001Z',
      },
      {
        d: 'M74.7044 62.5551C75.9935 64.9403 77.2827 67.3256 78.5731 69.7108C79.4702 71.3694 81.9759 69.8877 81.0776 68.2263C79.7884 65.8411 78.4993 63.4572 77.2088 61.072C76.3118 59.412 73.806 60.8937 74.7044 62.5551Z',
      },
      {
        d: 'M11.9353 45.1821C13.2257 47.5673 14.5149 49.9525 15.804 52.3377C16.7011 53.9964 19.2082 52.5146 18.3098 50.8533C17.0207 48.468 15.7302 46.0842 14.441 43.6989C13.544 42.0389 11.0369 43.5207 11.9353 45.1821Z',
      },
      {
        d: 'M23.7471 53.6323C25.0362 56.0176 26.3254 58.4014 27.6159 60.7866C28.5129 62.4466 31.0187 60.9649 30.1203 59.3035C28.8312 56.9183 27.542 54.5331 26.2515 52.1478C25.3545 50.4878 22.8487 51.971 23.7471 53.6323Z',
      },
      {
        d: 'M20.3684 63.1488C21.6576 65.5341 22.9467 67.9193 24.2372 70.3045C25.1342 71.9632 27.6413 70.4814 26.7416 68.8201C25.4525 66.4348 24.1633 64.0496 22.8742 61.6657C21.9758 60.0057 19.4701 61.4875 20.3684 63.1488Z',
      },
      {
        d: 'M33.8643 64.7905C35.1534 67.1744 36.4426 69.5596 37.7331 71.9449C38.6301 73.6049 41.1359 72.1231 40.2375 70.4604C38.9483 68.0765 37.6592 65.6913 36.3687 63.306C35.4717 61.646 32.9659 63.1292 33.8643 64.7905Z',
      },
      {
        d: 'M61.856 45.9629L61.5471 46.1085H61.9245L61.856 45.9629Z',
      },
      {
        d: 'M47.8582 45.8301L47.4258 46.1077H48.0328L47.8582 45.8301Z',
      },
      {
        d: 'M67.9728 64.696H63.2996C62.6806 64.696 62.1058 65.0076 61.7621 65.5315C61.4196 66.054 61.3552 66.7125 61.5942 67.2922L65.9813 77.9243L48.1173 58.7296H52.589C53.2631 58.7296 53.8849 58.3567 54.2085 57.758C54.5335 57.158 54.5106 56.4287 54.1481 55.8504L49.7314 48.8145H46.9799L51.7269 56.377H47.0229C46.2843 56.377 45.6169 56.8219 45.3255 57.5077C45.0328 58.1948 45.1711 58.9908 45.6773 59.5351L66.0726 81.4498C66.4379 81.843 66.92 82.0457 67.4115 82.0457C67.7579 82.0457 68.1084 81.9451 68.424 81.7369C69.1854 81.2362 69.4741 80.2932 69.1236 79.4428L64.01 67.0486H68.7167C69.3532 67.0486 69.9374 66.722 70.2785 66.1778C70.6182 65.6335 70.6612 64.96 70.3899 64.379L63.1774 48.8145H60.6139L67.9728 64.696Z',
      },
    ],
  },
  43: {
    width: 100,
    height: 69,
    paths: [
      {
        d: 'M31.7409 40.9278H9.32578C5.5682 40.9278 2.51214 37.5797 2.51214 33.4627C2.51214 29.344 5.5682 25.9942 9.32578 25.9942C9.57729 25.9942 9.85153 26.0156 10.1879 26.0616L11.297 26.2127L11.4091 25.0003C12.094 17.6025 17.7349 12.0234 24.5303 12.0234C30.0894 12.0234 35.0788 15.8874 36.9455 21.6374L37.2985 22.7233L38.3046 22.3734C39.1576 22.0793 40.0409 21.9298 40.9303 21.9298C41.1061 21.9298 41.2773 21.9462 41.4515 21.9577C41.5364 21.1905 41.6061 20.4052 41.644 19.5887C41.4076 19.5723 41.1712 19.5493 40.9303 19.5493C40.1591 19.5493 39.3879 19.6397 38.6349 19.8171C36.2273 13.7155 30.6091 9.64453 24.5303 9.64453C16.944 9.64453 10.6349 15.4931 9.37881 23.6154C9.36214 23.6154 9.34396 23.6154 9.32578 23.6154C4.36517 23.6154 0.330322 28.033 0.330322 33.4627C0.330322 38.8907 4.36517 43.3083 9.32578 43.3083H28.2667L28.2379 43.3017C28.2379 43.3017 29.7424 42.5871 31.7409 40.9278Z',
      },
      {
        d: 'M34.0091 63.0724C31.5061 63.1857 28.9455 62.9919 26.3167 62.4005C21.2303 61.2554 17.1258 58.18 13.5788 54.1665C13.0561 53.575 12.5606 52.9557 11.994 52.2805C24.1227 53.6637 34.2394 49.6897 41.5061 39.0752C48.7273 28.5264 49.5167 16.9097 44.953 4.75753C45.0167 4.77067 45.0955 4.76739 45.1576 4.80189C52.2015 8.62481 57.4424 14.25 60.0137 22.3788C60.8409 22.116 61.7409 21.8876 62.6515 21.748C62.5621 21.4588 62.4879 21.173 62.3909 20.8822C58.9394 10.4796 51.644 4.08396 42.3031 0.218315C41.6773 -0.0412566 41.0818 0.0622434 40.6409 0.643815C40.2015 1.22374 40.2121 1.85624 40.5743 2.48874C40.6788 2.66781 40.7803 2.85017 40.8803 3.03417C44.1031 8.87782 45.6894 15.1913 44.8879 21.973C43.5167 33.5667 37.9652 42.0832 28.1727 47.0759C22.1121 50.1661 15.7334 50.2827 9.29396 48.5479C8.38184 48.3031 7.74396 48.451 7.36214 49.0835C6.97729 49.7209 7.01972 50.3287 7.65002 51.1485C9.07275 53 10.4379 54.9205 11.997 56.6324C18.5334 63.8018 26.4197 67.0251 35.7379 65.9753C35.8924 65.9457 36.0485 65.9195 36.2046 65.8915C35.4258 65.1145 34.6637 64.1895 34.0091 63.0724Z',
      },
      {
        d: 'M85.7895 38.4496C84.844 38.4496 83.8985 38.5597 82.9758 38.7782C80.0258 31.2999 73.141 26.3105 65.691 26.3105C56.394 26.3105 48.6637 33.4783 47.1243 43.434C47.1031 43.4324 47.0804 43.4324 47.0592 43.4324C40.9804 43.4324 36.0349 48.8473 36.0349 55.5008C36.0349 62.1528 40.9804 67.566 47.0592 67.566H85.7895C93.1213 67.566 99.0864 61.034 99.0864 53.007C99.0864 44.9816 93.1213 38.4496 85.7895 38.4496ZM96.4122 53.007C96.4122 59.4273 91.647 64.6483 85.7895 64.6483H47.0592C42.4546 64.6483 38.7092 60.5444 38.7092 55.5008C38.7092 50.454 42.4546 46.3485 47.0592 46.3485C47.3667 46.3485 47.7016 46.3748 48.1152 46.4306L49.4743 46.6146L49.6107 45.1295C50.4501 36.0642 57.3637 29.2283 65.691 29.2283C72.5031 29.2283 78.6182 33.963 80.9061 41.0108L81.3395 42.3399L82.5713 41.9111C83.6167 41.5497 84.7001 41.3673 85.7895 41.3673C91.647 41.3673 96.4122 46.5883 96.4122 53.007Z',
      },
    ],
  },
  44: {
    width: 100,
    height: 69,
    paths: [
      {
        d: 'M31.7409 40.9278H9.32578C5.5682 40.9278 2.51214 37.5797 2.51214 33.4627C2.51214 29.344 5.5682 25.9942 9.32578 25.9942C9.57729 25.9942 9.85153 26.0156 10.1879 26.0616L11.297 26.2127L11.4091 25.0003C12.094 17.6025 17.7349 12.0234 24.5303 12.0234C30.0894 12.0234 35.0788 15.8874 36.9455 21.6374L37.2985 22.7233L38.3046 22.3734C39.1576 22.0793 40.0409 21.9298 40.9303 21.9298C41.1061 21.9298 41.2773 21.9462 41.4515 21.9577C41.5364 21.1905 41.6061 20.4052 41.644 19.5887C41.4076 19.5723 41.1712 19.5493 40.9303 19.5493C40.1591 19.5493 39.3879 19.6397 38.6349 19.8171C36.2273 13.7155 30.6091 9.64453 24.5303 9.64453C16.944 9.64453 10.6349 15.4931 9.37881 23.6154C9.36214 23.6154 9.34396 23.6154 9.32578 23.6154C4.36517 23.6154 0.330322 28.033 0.330322 33.4627C0.330322 38.8907 4.36517 43.3083 9.32578 43.3083H28.2667L28.2379 43.3017C28.2379 43.3017 29.7424 42.5871 31.7409 40.9278Z',
      },
      {
        d: 'M34.0091 63.0724C31.5061 63.1857 28.9455 62.9919 26.3167 62.4005C21.2303 61.2554 17.1258 58.18 13.5788 54.1665C13.0561 53.575 12.5606 52.9557 11.994 52.2805C24.1227 53.6637 34.2394 49.6897 41.5061 39.0752C48.7273 28.5264 49.5167 16.9097 44.953 4.75753C45.0167 4.77067 45.0955 4.76739 45.1576 4.80189C52.2015 8.62481 57.4424 14.25 60.0137 22.3788C60.8409 22.116 61.7409 21.8876 62.6515 21.748C62.5621 21.4588 62.4879 21.173 62.3909 20.8822C58.9394 10.4796 51.644 4.08396 42.3031 0.218315C41.6773 -0.0412566 41.0818 0.0622434 40.6409 0.643815C40.2015 1.22374 40.2121 1.85624 40.5743 2.48874C40.6788 2.66781 40.7803 2.85017 40.8803 3.03417C44.1031 8.87782 45.6894 15.1913 44.8879 21.973C43.5167 33.5667 37.9652 42.0832 28.1727 47.0759C22.1121 50.1661 15.7334 50.2827 9.29396 48.5479C8.38184 48.3031 7.74396 48.451 7.36214 49.0835C6.97729 49.7209 7.01972 50.3287 7.65002 51.1485C9.07275 53 10.4379 54.9205 11.997 56.6324C18.5334 63.8018 26.4197 67.0251 35.7379 65.9753C35.8924 65.9457 36.0485 65.9195 36.2046 65.8915C35.4258 65.1145 34.6637 64.1895 34.0091 63.0724Z',
      },
      {
        d: 'M85.7895 38.4496C84.844 38.4496 83.8985 38.5597 82.9758 38.7782C80.0258 31.2999 73.141 26.3105 65.691 26.3105C56.394 26.3105 48.6637 33.4783 47.1243 43.434C47.1031 43.4324 47.0804 43.4324 47.0592 43.4324C40.9804 43.4324 36.0349 48.8473 36.0349 55.5008C36.0349 62.1528 40.9804 67.566 47.0592 67.566H85.7895C93.1213 67.566 99.0864 61.034 99.0864 53.007C99.0864 44.9816 93.1213 38.4496 85.7895 38.4496ZM96.4122 53.007C96.4122 59.4273 91.647 64.6483 85.7895 64.6483H47.0592C42.4546 64.6483 38.7092 60.5444 38.7092 55.5008C38.7092 50.454 42.4546 46.3485 47.0592 46.3485C47.3667 46.3485 47.7016 46.3748 48.1152 46.4306L49.4743 46.6146L49.6107 45.1295C50.4501 36.0642 57.3637 29.2283 65.691 29.2283C72.5031 29.2283 78.6182 33.963 80.9061 41.0108L81.3395 42.3399L82.5713 41.9111C83.6167 41.5497 84.7001 41.3673 85.7895 41.3673C91.647 41.3673 96.4122 46.5883 96.4122 53.007Z',
      },
    ],
  },
};
