import { getRSSAppSettings } from '@/helpers/utils';

export default {
  computed: {
    layoutRotation() {
      return this.$store.getters.getPlayerRotation;
    },
  },

  methods: {
    getRSSAppSettings,

    defaultStyling(widget) {
      const position = widget.position[this.layoutRotation];
      const usePixels = position.usePixels;

      return Object.assign(widget, {
        left: usePixels ? `${position.xPixels}px` : `${position.x}%`,
        top: usePixels ? `${position.yPixels}px` : `${position.y}%`,
        height: usePixels ? `${position.heightPixels}px` : `${position.height}%`,
        width: usePixels ? `${position.widthPixels}px` : `${position.width}%`,
        borderRadius: `${widget.object.borderRadius || 0}px`,
        zIndex: 100 + position.zIndex,
        opacity: position.isHidden ? 0 : 1,
      });
    },
  },
};
