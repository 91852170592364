import {
  PICFLOW_SCAP_API_FILES,
  PICFLOW_SCAP_API_PATH,
  PICFLOW_SUPPORTED_WEBOS_VERSION,
  PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH,
} from '@/constants/webOS';
import { SCREEN_ORIENTATION } from '@/constants';
import config from '@/config';
import moment from 'moment';
import { enableImmediateRestart, enableWakeOnLan } from '@/utils/webOsSignage/api/power/power';
import { enableKeepAliveMode, tryGetWebOSVersion } from '@/utils/webOsSignage/api/custom/custom';

export function isSupportedWebOSVersion(webOSVersion) {
  switch (webOSVersion) {
    case PICFLOW_SUPPORTED_WEBOS_VERSION['3.0']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['3.2']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['4.0']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['4.1']:
    case PICFLOW_SUPPORTED_WEBOS_VERSION['6.0']:
      return true;
    default:
      return false;
  }
}

export async function loadScapApi(webOSVersion) {
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH[webOSVersion] + PICFLOW_SCAP_API_FILES.DeviceInfo,
    'deviceInfoSCAPLib',
  );
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH[webOSVersion] + PICFLOW_SCAP_API_FILES.Storage,
    'storageSCAPLib',
  );
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH[webOSVersion] + PICFLOW_SCAP_API_FILES.Signage,
    'signageSCAPLib',
  );
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH['6.0'] + PICFLOW_SCAP_API_FILES.Power,
    'powerSCAPLib',
  );
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_SCAP_API_PATH['6.0'] + PICFLOW_SCAP_API_FILES.Time,
    'timeSCAPLib',
  );
}

export async function webOSAppInitialization() {
  console.info('Initialize player as WebOS app');

  // load Cordova and CustomJS library before initializing
  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.CordovaLib,
    'cordovaLib',
  );

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.CustomLib,
    'customLib',
  );

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.WebOSLib,
    'webOSLib',
  );

  const { webOSVersion } = await tryGetWebOSVersion();
  var initializedAsWebOSVersion = null;

  if (isSupportedWebOSVersion(webOSVersion)) {
    await loadScapApi(webOSVersion);
    initializedAsWebOSVersion = webOSVersion;
  } else {
    const defaultWebOSVersion = PICFLOW_SUPPORTED_WEBOS_VERSION['6.0'];
    console.error(
      `Unsupported WebOS version: ${webOSVersion}. Fallback to WebOS version: ${defaultWebOSVersion}`,
    );

    await loadScapApi(defaultWebOSVersion);
    initializedAsWebOSVersion = defaultWebOSVersion;
  }

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.UtilsLib,
    'utilsLib',
  );

  await loadScriptAsync(
    config.playerBaseUrl + PICFLOW_WEBOS_EXTERNAL_SCRIPT_PATH.WebClientLib,
    'webClientLib',
  );

  // system configurations
  await enableImmediateRestart();
  await enableKeepAliveMode();
  await enableWakeOnLan();

  return initializedAsWebOSVersion;
}

export async function getWebOsDeviceInfo() {
  const status =
    localStorage.getItem('screenId') && localStorage.getItem('sessionToken')
      ? 2
      : localStorage.getItem('sessionToken')
      ? 1
      : 0;

  const date = new Date();
  const current = moment(date).format('YYYY-MM-DD HH:mm:ss');
  const timezone = date.toString().split(' ')[5]; // expect format: UTC+/-H:m, such as UTC+07:00

  var screenOrientation = null;
  try {
    screenOrientation = await getScreenOrientationInfo();
  } catch (_error) {}

  var webOSVersion = null;
  try {
    webOSVersion = await getWebOSVersion();
  } catch (_error) {}

  var platformInfo = null;
  try {
    platformInfo = await getPlatformInfo();
  } catch (_error) {}

  var storageInfo = null;
  try {
    storageInfo = await getStorageInfo();
  } catch (_error) {}

  var networkInfo = null;
  try {
    networkInfo = await getNetworkInfo();
  } catch (_error) {}

  var networkMacInfo = null;
  try {
    networkMacInfo = await getNetworkMacInfo();
  } catch (_error) {}

  var systemUsageInfo = null;
  try {
    systemUsageInfo = await getSystemUsageInfo();
  } catch (_error) {}

  return {
    status: status,
    current: current,
    timezone: timezone,
    screenOrientation: screenOrientation,
    playerVersion: config.version,
    platformInfo: {
      os: 'webOS',
      osVersion: webOSVersion,
      serialNumber: platformInfo?.serialNumber,
      modelName: platformInfo?.modelName,
      firmwareVersion: platformInfo?.firmwareVersion,
      sdkVersion: platformInfo?.sdkVersion,
    },
    storage: {
      total: kbToBytes(storageInfo?.total),
      free: kbToBytes(storageInfo?.free),
      used: kbToBytes(storageInfo?.used),
    },
    network: getNetwork(networkInfo, networkMacInfo),
    systemUsage: {
      memory: systemUsageInfo?.memory,
    },
  };
}

export async function getWebOSVersion() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      resolve(successObject.webOSVersion);
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get webos version');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var custom = new Custom();
    custom.Signage.getwebOSVersion(successCallbackObject, failureCallbackObject);
  });
}

export async function getScreenOrientationInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      var screenOrientation = '';
      if (successObject.nativePortrait === Custom.NATIVEPORTRAIT.OFF) {
        screenOrientation = SCREEN_ORIENTATION.LANDSCAPE;
      } else if (successObject.nativePortrait === Custom.NATIVEPORTRAIT.DEGREE_90) {
        screenOrientation = SCREEN_ORIENTATION.PORTRAIT;
      } else if (successObject.nativePortrait === Custom.NATIVEPORTRAIT.DEGREE_180) {
        screenOrientation = SCREEN_ORIENTATION.REVERSE_LANDSCAPE;
      } else if (successObject.nativePortrait === Custom.NATIVEPORTRAIT.DEGREE_270) {
        screenOrientation = SCREEN_ORIENTATION.REVERSE_PORTRAIT;
      }

      resolve(screenOrientation);
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get screen orientation info');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var custom = new Custom();
    custom.Configuration.getNativePortraitMode(successCallbackObject, failureCallbackObject);
  });
}

export async function getApplicationInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      console.log(JSON.stringify(successObject));
      resolve({
        allowAudioCapture: successObject?.allowAudioCapture,
        allowVideoCapture: successObject?.allowVideoCapture,
        appDescription: successObject?.appDescription,
        crossDomainSecurity: successObject?.crossDomainSecurity,
        disalbeBackHistoryAPI: successObject?.disalbeBackHistoryAPI,
        icon: successObject?.icon,
        id: successObject?.id,
        inspectable: successObject?.inspectable,
        largeIcon: successObject?.largeIcon,
        main: successObject?.main,
        splashBackground: successObject?.splashBackground,
        supportedOrientations: successObject?.supportedOrientations,
        title: successObject?.title,
        type: successObject?.type,
        useVirtualKeyboard: successObject?.useVirtualKeyboard,
        version: successObject?.version,
        vendor: successObject?.vendor,
      });
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get application info ');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var custom = new Custom();
    custom.Signage.getApplicationInfo(successCallbackObject, failureCallbackObject);
  });
}

export async function getPlatformInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      resolve({
        modelName: successObject.modelName,
        serialNumber: successObject.serialNumber,
        firmwareVersion: successObject.firmwareVersion,
        hardwareVersion: successObject.hardwareVersion,
        sdkVersion: successObject.sdkVersion,
        manufacturer: successObject.manufacturer,
      });
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get platform info');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var deviceInfo = new DeviceInfo();
    deviceInfo.getPlatformInfo(successCallbackObject, failureCallbackObject);
  });
}

export async function getNetworkInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      resolve({
        isInternetConnectionAvailable: successObject.isInternetConnectionAvailable,
        wired: {
          state: successObject.wired.state,
          interfaceName: successObject.wired?.interfaceName,
          ipAddress: successObject.wired?.ipAddress,
          netmask: successObject.wired?.netmask,
          gateway: successObject.wired?.gateway,
          onInternet: successObject.wired?.onInternet,
          method: successObject.wired?.method,
          dns1: successObject.wired?.dns1,
          dns2: successObject.wired?.dns2,
          dns3: successObject.wired?.dns3,
          dns4: successObject.wired?.dns4,
          dns5: successObject.wired?.dns5,
        },
        wifi: {
          state: successObject.wifi.state,
          interfaceName: successObject.wifi?.interfaceName,
          ipAddress: successObject.wifi?.ipAddress,
          netmask: successObject.wifi?.netmask,
          gateway: successObject.wifi?.gateway,
          onInternet: successObject.wifi?.onInternet,
          method: successObject.wifi?.method,
          dns1: successObject.wifi?.dns1,
          dns2: successObject.wifi?.dns2,
        },
      });
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get network info');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var deviceInfo = new DeviceInfo();
    deviceInfo.getNetworkInfo(successCallbackObject, failureCallbackObject);
  });
}

export async function getNetworkMacInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      resolve({
        wiredInfo: { macAddress: successObject?.wiredInfo?.macAddress },
        wifiInfo: { macAddress: successObject?.wifiInfo?.macAddress },
      });
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get network mac info');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var deviceInfo = new DeviceInfo();
    deviceInfo.getNetworkMacInfo(successCallbackObject, failureCallbackObject);
  });
}

export async function getSystemUsageInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      resolve({
        memory: {
          total: successObject?.memory?.total,
          free: successObject?.memory?.free,
          used: successObject?.memory?.used,
          buffer: successObject?.memory?.buffer,
          cached: successObject?.memory?.cached,
        },
      });
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get system usage info');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var deviceInfo = new DeviceInfo();
    deviceInfo.getSystemUsageInfo(successCallbackObject, failureCallbackObject, {
      cpus: false,
      memory: true,
    });
  });
}

export async function getStorageInfo() {
  return new Promise((resolve, reject) => {
    const successCallbackObject = (successObject) => {
      resolve({
        free: successObject.free,
        total: successObject.total,
        used: successObject.used,
      });
    };

    const failureCallbackObject = (cbObject) => {
      console.error('Failed to get storage info');
      console.error('[' + cbObject.errorCode + '] ' + cbObject.errorText);
      reject();
    };

    var storage = new Storage();
    storage.getStorageInfo(successCallbackObject, failureCallbackObject);
  });
}

//#region Helper

function getNetwork(networkInfo, networkMacInfo) {
  if (networkInfo === null) return null;

  var type = null;

  if (networkInfo.wired.state === 'connected' && networkInfo.wifi.state === 'disconnected') {
    type = 'wired';
  } else if (networkInfo.wifi.state === 'connected' && networkInfo.wired.state === 'disconnected') {
    type = 'wifi';
  } else if (networkInfo.wired.state === 'connected' && networkInfo.wifi.state === 'connected') {
    type = 'wired & wifi';
  }

  var network = null;

  if (type === 'wired') {
    let method = null;

    if (networkInfo.wired?.method && networkInfo.wired?.method === 'dhcp') {
      method = 'dhcp';
    } else if (networkInfo.wired?.method && networkInfo.wired?.method === 'manual') {
      method = 'static';
    }

    network = {
      type: type,
      ip: networkInfo.wired?.ipAddress,
      gateway: networkInfo.wired?.gateway,
      mac: networkMacInfo?.wiredInfo?.macAddress,
      netmask: networkInfo.wired?.netmask,
      dns: networkInfo.wired?.dns1,
      method: method,
    };
  } else if (type === 'wifi') {
    let method = null;

    if (networkInfo.wifi?.method && networkInfo.wifi?.method === 'dhcp') {
      method = 'dhcp';
    } else if (networkInfo.wifi?.method && networkInfo.wifi?.method === 'manual') {
      method = 'static';
    }

    network = {
      type: type,
      ip: networkInfo.wifi?.ipAddress,
      gateway: networkInfo.wifi?.gateway,
      mac: networkMacInfo?.wifiInfo?.macAddress,
      netmask: networkInfo.wifi?.netmask,
      dns: networkInfo.wifi?.dns1,
      method: method,
    };
  } else if (type === 'wired & wifi') {
    network = {
      type: type,
      ip: null,
      gateway: null,
      mac: null,
      netmask: null,
      dns: null,
      method: null,
    };
  }

  return network;
}

function kbToBytes(kb) {
  if (kb === null) return 0;

  return kb * 1024;
}

function loadScriptAsync(scriptUrl, id) {
  return new Promise((resolve, reject) => {
    var elem = document.getElementById(id);
    if (elem) {
      console.log(id + ' already exists.');
      //elem.remove();
      resolve();
      return;
    }

    var scriptElem = document.createElement('script');
    scriptElem.src = scriptUrl;
    scriptElem.id = id;
    scriptElem.async = false;

    scriptElem.onload = function (e) {
      console.log(e.target.src + ' is loaded.');
      resolve();
    };

    scriptElem.onerror = function () {
      reject(new Error('Failed to load script: ' + scriptUrl));
    };

    document.head.appendChild(scriptElem);
  });
}

//#endregion
