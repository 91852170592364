<template>
  <div class="widget-item app-widget" :id="widget.object.wid" :style="style">
    <RssViewer
      v-if="widget.object.item_type === `app/${APP_TYPES.RSS}`"
      :setting="getRSSAppSettings(widget.object, widget.object.item_id)"
      @saveData="saveAppData"
    ></RssViewer>

    <TableAppWebView
      v-if="widget.object.item_type === `app/${APP_TYPES.TABLE}`"
      :settings="widget.object"
    ></TableAppWebView>

    <TrafiklabViewer
      v-if="widget.object.item_type === `app/${APP_TYPES.TRAFIKLAB}`"
      :viewerStyles="widget.object.viewerStyles"
      :timetable="widget.object.timetable"
      :name="widget.object.name"
      :app="widget.object"
      alt="Trafiklab app"
      @saveData="saveAppData"
    />

    <WeatherView
      class="weather-app"
      v-else-if="isWeatherApp && !isOldWeatherApp"
      :app="widget.object"
      @saveData="saveAppData"
    />

    <WeatherViewOld
      class="weather-app"
      v-else-if="isOldWeatherApp"
      :app="widget.object"
      @saveData="saveAppData"
    />
  </div>
</template>

<script>
  import { APP_TYPES } from '@/models/layoutDesigner';
  import templateWidget from '@/components/widgets/templates/mixins/templateWidget';
  import { simpleTypeMixin } from '@/helpers';

  import RssViewer from '@/components/rssViewer/Index.vue';
  import TableAppWebView from '@/components/tableApp/TableAppWebView.vue';
  import TrafiklabViewer from '@/components/common/timetable/TrafiklabViewer.vue';
  import WeatherView from '@/components/apps/weather/WeatherView.vue';
  import WeatherViewOld from '@/components/apps/weather/WeatherViewOld.vue';

  import { CONTENT_TYPE_WEATHER_APP } from '@/constants';
  import { SET_APP_DATA } from '@/store/actions/player';

  export default {
    name: 'AppWidget',

    components: {
      RssViewer,
      TableAppWebView,
      TrafiklabViewer,
      WeatherView,
      WeatherViewOld,
    },

    mixins: [simpleTypeMixin, templateWidget],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        APP_TYPES,
      };
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return style;
      },

      isOldWeatherApp() {
        return (
          this.isWeatherApp &&
          !this.widget.object.weather &&
          (this.widget.object.visibility || this.widget.object.background)
        );
      },

      isWeatherApp() {
        return this.widget.object.item_type === CONTENT_TYPE_WEATHER_APP;
      },
    },

    methods: {
      saveAppData(data = null) {
        this.$store.commit(SET_APP_DATA, {
          appId: this.widget.object.item_id,
          appData: { appType: this.widget.object.item_type, data },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #6a6b6a;
    overflow: hidden;

    i {
      font-size: 60px;
    }
  }

  .widget-item {
    position: absolute;
  }

  .rss-viewer {
    overflow: hidden;
  }

  .weather-app {
    width: 100%;
    height: 100%;
  }

  .app-widget {
    overflow: hidden;
  }
</style>
