<template>
  <div class="image-container" :style="style">
    <img
      v-if="isWeb"
      :src="imageUrl"
      :style="imageStyle"
      :alt="widget.position.name"
      @error="handleImageError"
    />
    <template v-else>
      <img
        v-if="!isImageLoadError"
        :src="imageUrl"
        :style="imageStyle"
        :alt="widget.position.name"
        @error="handleImageError"
      />
      <img
        v-else
        :src="defaultImageUrl"
        :style="imageStyle"
        :alt="widget.position.name"
        @error="handleDefaultImageError"
      />
    </template>
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';
  import { getItemLocalUrl, getItemUrl } from '@/utils/mediaAsset';

  export default {
    name: 'ImageWidget',

    mixins: [playerMixins, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    data() {
      return { isImageLoadError: false };
    },

    computed: {
      style() {
        return Object.assign(this.defaultStyling(this.widget), {
          opacity: this.widget.position[this.layoutRotation].isHidden
            ? 0
            : Number(this.widget.position.opacity) / 100,
        });
      },

      imageStyle() {
        return {
          objectFit: this.widget.position.imageMode,
        };
      },

      isWeb() {
        return window.isWeb;
      },

      imageUrl() {
        return getItemLocalUrl(this.widget.object.item_url, this.widget.object.item_id);
      },

      defaultImageUrl() {
        return getItemUrl(this.widget.object.item_url);
      },
    },

    methods: {
      handleImageError() {
        this.isImageLoadError = true;
        console.warn(`Failed to load image: ${this.imageUrl}`);
      },

      handleDefaultImageError() {
        console.warn(`Failed to load default image: ${this.defaultImageUrl}`);
      },

      showImage() {
        //this.$emit('show-enlarged', this.widget)
      },
    },
  };
</script>

<style lang="scss" scoped>
  .image-container {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
