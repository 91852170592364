import { Timer } from '@/utils/webOsSignage/api/time/types';
import { OffOnTimer } from '@/utils/webOsSignage/watchdogManager/types';
import { reserveDailyOffTimer, reserveDailyOnTimer } from '@/utils/webOsSignage/api/time/time';
import moment from 'moment';
import {
  NEXT_KEEP_ALIVE_TICK_IN_MINUTES,
  STANDARD_OFF_MINUTE_VALUES,
  STANDARD_ON_MINUTE_VALUES,
} from '@/utils/webOsSignage/watchdogManager/constants';

export function getUserDefinedTimers(timers: Timer[]): Timer[] {
  const userDefinedTimers: Timer[] = [];
  for (const timer of timers) {
    if (
      timer?.minute !== undefined &&
      !(STANDARD_ON_MINUTE_VALUES.has(timer.minute) || STANDARD_OFF_MINUTE_VALUES.has(timer.minute))
    ) {
      userDefinedTimers.push(timer);
    }
  }

  return userDefinedTimers;
}

export function calculateNextOffOnTimer(localNow: Date, nextTickInMinute: number = 10): OffOnTimer {
  var overflowToNextHour = false;
  if (localNow.getMinutes() + nextTickInMinute >= 60) {
    overflowToNextHour = true;
  }

  const minute = moment(localNow).add(nextTickInMinute, 'minutes').toDate().getMinutes();
  const futureOffMinutes = Array.from(STANDARD_OFF_MINUTE_VALUES.values()).filter((value) => {
    return value - minute >= 0;
  });

  const offMinutes = Array.from(STANDARD_OFF_MINUTE_VALUES.values());
  const onMinutes = Array.from(STANDARD_ON_MINUTE_VALUES.values());

  // case when the next on value starts in the next hour
  if (!overflowToNextHour && futureOffMinutes.length === 0) {
    const nextHour = moment(localNow).add(1, 'hours').toDate().getHours();

    return {
      off: { hour: nextHour, minute: offMinutes[0] },
      on: { hour: nextHour, minute: onMinutes[0] },
    } as OffOnTimer;
  } else if (!overflowToNextHour && futureOffMinutes.length > 0) {
    const currentHour = localNow.getHours();
    const nextOffMinute = futureOffMinutes[0];
    const nextOffMinuteIndex = offMinutes.indexOf(nextOffMinute);
    const nextOnMinute = onMinutes[nextOffMinuteIndex];

    return {
      off: { hour: currentHour, minute: nextOffMinute },
      on: { hour: currentHour, minute: nextOnMinute },
    } as OffOnTimer;
  } else if (overflowToNextHour && futureOffMinutes.length > 0) {
    const nextHour = moment(localNow).add(1, 'hours').toDate().getHours();
    const nextOffMinute = futureOffMinutes[0];
    const nextOffMinuteIndex = offMinutes.indexOf(nextOffMinute);
    const nextOnMinute = onMinutes[nextOffMinuteIndex];

    return {
      off: { hour: nextHour, minute: nextOffMinute },
      on: { hour: nextHour, minute: nextOnMinute },
    } as OffOnTimer;
  }

  throw new Error('Invalid case when calculating next off/on timer');
}

export async function addKeepAliveTimers(localNow: Date) {
  const nextTimer = calculateNextOffOnTimer(localNow, NEXT_KEEP_ALIVE_TICK_IN_MINUTES);
  const nextFallbackTimer = calculateNextOffOnTimer(localNow, NEXT_KEEP_ALIVE_TICK_IN_MINUTES * 2);

  await reserveDailyOffTimer(nextTimer.off.hour, nextTimer.off.minute);
  await reserveDailyOnTimer(nextTimer.on.hour, nextTimer.on.minute);

  await reserveDailyOffTimer(nextFallbackTimer.off.hour, nextFallbackTimer.off.minute);
  await reserveDailyOnTimer(nextFallbackTimer.on.hour, nextFallbackTimer.on.minute);
}

export function isOffTimersTriggeringSoon(
  userDefinedTimers: Timer[],
  localNow: Date,
  offTimerThreshold: number,
) {
  const userOffTimers = userDefinedTimers.filter((timer) => timer.type === 'OFFTIMER');

  if (userOffTimers.length === 0) {
    return false;
  }

  for (const offTimer of userOffTimers) {
    if (!(offTimer.week && offTimer.hour && offTimer.minute)) {
      continue;
    }

    // check if the current day is in the user-defined weekday bitmask
    if (!(offTimer.week & convertDateDayToTimerWeekday(localNow.getDay()))) {
      continue;
    }

    var diff =
      offTimer.hour * 60 + offTimer.minute - (localNow.getHours() * 60 + localNow.getMinutes());

    if (diff < 0) {
      diff += 1440;
    }

    if (diff <= offTimerThreshold) {
      return true;
    }
  }

  return false;
}

function convertDateDayToTimerWeekday(day: number) {
  if (day === 0) {
    return 64;
  } else if (day === 1) {
    return 1;
  } else if (day === 2) {
    return 2;
  } else if (day === 3) {
    return 4;
  } else if (day === 4) {
    return 8;
  } else if (day === 5) {
    return 16;
  } else if (day === 6) {
    return 32;
  }

  return 0;
}
