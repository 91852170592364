export default {
  version: process.env.VUE_APP_VERSION,
  timezone: 'Europe/Stockholm',
  locale: process.env.VUE_APP_LOCALE,
  imageBaseUrl: process.env.VUE_APP_IMAGE_BASE_URL || 'https://media.picler.se',
  baseUrl:
    process.env.VUE_APP_IMAGE_BASE_URL ||
    `${location.protocol}//s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_AWS_S3_BUCKET}`,
  s3BaseUrl: `https://s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${process.env.VUE_APP_AWS_S3_BUCKET}/`,
  alternateS3BaseUrl: `https://${process.env.VUE_APP_AWS_S3_BUCKET}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/`,
  playerBaseUrl: process.env.VUE_APP_PLAYER_BASE_URL,
  apiUrl: process.env.VUE_APP_API_URL || 'https://backend.picler.se/',
  transportApiUrl: process.env.VUE_APP_TRANSPORT_API_URL,
  environment: process.env.VUE_APP_ENVIRONMENT || 'UNDEFINED',
  loggingHost: process.env.VUE_APP_LOGGING_HOST,
  logErrors: process.env.VUE_APP_LOG_ERRORS,
  sentry: {
    dns: process.env.VUE_APP_SENTRY_DSN,
    tracesSampleRate: Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
    replaysSessionSampleRate: Number(process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    replaysOnErrorSampleRate: Number(process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    startSessionReplayOnMounted:
      process.env.VUE_APP_SENTRY_START_SESSION_REPLAY_ON_MOUNTED === 'true',
    stopSessionReplayAfterMinutes: Number(
      process.env.VUE_APP_SENTRY_STOP_SESSION_REPLAY_AFTER_MINUTES,
    ),
  },
};
