import { Timer } from '@/utils/webOsSignage/api/time/types';

export async function getAllOnOffTimer(): Promise<Timer[]> {
  return new Promise<any>((resolve, reject) => {
    const successCallbackObject = (successObject: any) => {
      resolve(successObject.timerList as Timer[]);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const time = new Time();
    time.getAllOnOffTimers(successCallbackObject, failureCallbackObject);
  });
}

export async function cancelOnOffTimer(timerId: number) {
  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const time = new Time();
    time.cancelOnOffTimer(successCallbackObject, failureCallbackObject, {
      id: timerId,
    });
  });
}

export async function clearAllOnOffTimers() {
  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const time = new Time();
    time.clearAllOnOffTimers(successCallbackObject, failureCallbackObject);
  });
}

export async function reserveDailyOnTimer(hour: number, minute: number) {
  if (hour < 0 || hour > 23) {
    throw Error('Hour can only be between 0 - 23');
  }

  if (minute < 0 || minute > 59) {
    throw Error('Minute can only be between 0 - 59');
  }

  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const time = new Time();
    time.reserveOnOffTimer(successCallbackObject, failureCallbackObject, {
      // @ts-ignore: SCAP API
      type: Time.TimerType.ONTIMER,
      hour: hour,
      minute: minute,
      // @ts-ignore: SCAP API
      week: Time.TimerWeek.EVERYDAY,
    });
  });
}

export async function reserveDailyOffTimer(hour: number, minute: number) {
  if (hour < 0 || hour > 23) {
    throw Error('Hour can only be between 0 - 23');
  }

  if (minute < 0 || minute > 59) {
    throw Error('Minute can only be between 0 - 59');
  }

  await new Promise((resolve, reject) => {
    const successCallbackObject = (_successObject: any) => {
      resolve(true);
    };

    const failureCallbackObject = (cbObject: any) => {
      console.log('Error Code [' + cbObject.errorCode + ']: ' + cbObject.errorText);
      reject();
    };

    // @ts-ignore: SCAP API
    const time = new Time();
    time.reserveOnOffTimer(successCallbackObject, failureCallbackObject, {
      // @ts-ignore: SCAP API
      type: Time.TimerType.OFFTIMER,
      hour: hour,
      minute: minute,
      // @ts-ignore: SCAP API
      week: Time.TimerWeek.EVERYDAY,
    });
  });
}
