import config from '../config';
import http from '@/utils/http';
import { Departure } from '@/types/api/transport';

export async function getDepartures(
  stopId: unknown,
  transportTypes: unknown,
  lang: unknown,
  departuresAfter: unknown,
  duration: number = 60,
): Promise<Departure[] | null> {
  try {
    const apiUrl = `${config.apiUrl}userapi/traffiklab/timetables/departures`;
    const response = await http.get(apiUrl, {
      'axios-retry': {
        retries: 0,
      },
      params: {
        stopId: stopId,
        transportTypes: transportTypes,
        lang: lang,
        departuresAfter: departuresAfter,
        duration: duration,
      },
    });

    return response.data;
  } catch (error: any) {
    console.log(`Trafiklab API request failed: ${error?.message}.`);

    return null;
  }
}
