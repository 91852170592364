import {
  cancelOnOffTimer,
  clearAllOnOffTimers,
  getAllOnOffTimer,
} from '@/utils/webOsSignage/api/time/time';
import { SentryLogger } from '@/utils/sentryLogger';
import {
  MAX_NUMBER_OF_ALLOWED_TIMERS,
  NEXT_KEEP_ALIVE_TICK_IN_MINUTES,
  NUMBER_OF_KEEP_ALIVE_TIMER,
} from '@/utils/webOsSignage/watchdogManager/constants';
import {
  addKeepAliveTimers,
  getUserDefinedTimers,
  isOffTimersTriggeringSoon,
} from '@/utils/webOsSignage/watchdogManager/utils';
import appCard from '@/components/cardGrid/AppCard.vue';

/**
 * Watchdog schedules a system reboot "10" minutes into the future and resets
 * the countdown by an additional "10" minutes each time `runOnce` is called.
 *
 * This is designed under the assumption that while the Player code is running the system
 * should not trigger a reboot. A reboot will only occur if the Player code crashes due
 * to issues like out of memory, ensuring that the system reboots and attempts to restart the Player.
 */
export class WatchdogManager {
  static isRunning = false;

  public static async runOnce() {
    if (this.isRunning) {
      return false;
    }

    console.log(`Start running watchdog: ${new Date().toISOString()}`);
    this.isRunning = true;

    try {
      const timers = await getAllOnOffTimer();
      var userDefinedTimers = getUserDefinedTimers(timers);

      var clearedAllTimers = false;

      if (userDefinedTimers.length + NUMBER_OF_KEEP_ALIVE_TIMER > MAX_NUMBER_OF_ALLOWED_TIMERS) {
        // we clear the timers as we cannot know what on/off pair we need to keep
        await clearAllOnOffTimers();
        clearedAllTimers = true;
        userDefinedTimers = [];

        SentryLogger.error('Maximum number of on/off timers exceeded. Cleared all timers');
      } else if (userDefinedTimers.length % 2 != 0) {
        // timers cannot be odd as we need to have an on/off pairs
        await clearAllOnOffTimers();
        clearedAllTimers = true;
        userDefinedTimers = [];

        SentryLogger.error('User defined on/off timers cannot be odd number. Cleared all timers');
      }

      const localNow = new Date();
      if (clearedAllTimers) {
        await addKeepAliveTimers(localNow);
      } else {
        const keepAliveTimers = timers.filter((timer) => {
          return !userDefinedTimers.some((userDefinedTimer) => userDefinedTimer?.id == timer?.id);
        });

        for (const keepAliveTimer of keepAliveTimers) {
          try {
            if (keepAliveTimer?.id !== undefined) {
              await cancelOnOffTimer(keepAliveTimer?.id);
            } else {
              await clearAllOnOffTimers();
              SentryLogger.error(
                'System returned an undefined keep alive timer id. Cleared all timers',
              );
              break;
            }
          } catch (error: any) {
            await clearAllOnOffTimers();
            SentryLogger.error('Failed to clear a keep alive timer. Cleared all timers', error);
          }
        }

        // skip adding keep-alive timers if an off/on interval is soon scheduled to avoid keeping the screen awake after shutdown
        if (
          !isOffTimersTriggeringSoon(
            userDefinedTimers,
            localNow,
            NEXT_KEEP_ALIVE_TICK_IN_MINUTES * 2,
          )
        ) {
          await addKeepAliveTimers(localNow);
        }
      }
    } catch (error: any) {
      SentryLogger.error('Failed to run watchdog', error);
    }

    console.log(`Finished running watchdog: ${new Date().toISOString()}`);
    this.isRunning = false;
  }
}
