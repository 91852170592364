/**
 * The timers can be reserved up to 7 in webOS 4.1 and earlier models and up to 21 in webOS 6.0 and later models for each on/off timer.
 * See: https://webossignage-docs.developer.lge.com/apis/scap-api/scap-api-v1.8/time#reserveonofftimer
 */
export const MAX_NUMBER_OF_ALLOWED_TIMERS = 7;

export const NUMBER_OF_KEEP_ALIVE_TIMER = 4;
export const NEXT_KEEP_ALIVE_TICK_IN_MINUTES = 10;
export const STANDARD_OFF_MINUTE_VALUES = Object.freeze(new Set([2, 12, 22, 32, 42, 52]));
export const STANDARD_ON_MINUTE_VALUES = Object.freeze(new Set([4, 14, 24, 34, 44, 54]));
